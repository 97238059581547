<script lang="ts">
	import { fade, slide } from 'svelte/transition';
    import type { ProviderName } from '$lib/server/entities/auth_provider/AuthProviderEntity';
    import features_framework_clientside from '$lib/features/features_framework_clientside';
    import { notification_store } from '$lib/stores/notification_store.svelte';
	import env_client from '$lib/env/env_clientside';
	import Icon from '../Icon.svelte';
	import PreloadingIndicator from '../PreloadingIndicator.svelte';
	import { show_login } from '$lib/stores/login_store';
	import { show_loading } from '$lib/stores/loading_indicator_store';
	import { Capacitor } from '@capacitor/core';
    import { Browser } from '@capacitor/browser';

    let reviewer_credentials_expanded = $state(false);
    let show_loading_indicator = $state(false);

    let auth_providers: {provider: ProviderName, label: string, icon_path: string, class_override: string}[] = 
      [
        {provider: 'google', label: "Continue with Google", icon_path: "/logo/partners/google_logo.png", class_override: ""},
        {provider: 'apple', label: "Continue with Apple", icon_path: "/logo/partners/apple_logo_black.png", class_override: "-mt-0.5"},
        {provider: 'facebook', label: "Continue with Facebook", icon_path: "/logo/partners/facebook_logo_250.png", class_override: ""},
      ];

    async function login_with_provider(provider_name: ProviderName) {

        show_loading_indicator = true;

        let authentication_url = `${env_client.www_endpoint()}/auth/login/${provider_name}`;
        let is_ios = Capacitor.isNativePlatform() 
                     && Capacitor.getPlatform() === 'ios' 
                     && Capacitor.isPluginAvailable('Browser');
        if (is_ios) {

            let url = new URL(authentication_url);

            /**
             * To ensure fetch request returns URL as a payload and not an HTTP header,
             * set 'in_place_request' to true. 
             */
            url.searchParams.append('in_place_request', 'true');
            
            let response = await fetch(url);
            
            // console.log("Response from provider login: ", response);

            let response_json = await response.json();
            let partner_oauth_url = response_json.partner_oauth_url;
            if (!partner_oauth_url) {

                let response_message = await response.text();
                notification_store.notify_message("Login failed", response_message);
                return;
            }

            try {
                // alert("Opening: " + partner_oauth_url);
                Browser.open({ url: partner_oauth_url });
                //alert("Finished!");
            } catch (error) {
                notification_store.notify_message("Unable to open login browser", JSON.stringify(error));
            }

        } else {
            window.location.href = authentication_url;
        }
    }

    async function handle_credentials_login(event: any) {

        show_loading_indicator = true;

        event.preventDefault();

        let email = event.target.swimerize_email.value;
        if (email === undefined || email === "") {
            notification_store.notify_message("Missing email", "Please enter your email address.");
            show_loading_indicator = false;
            return false;
        }

        let password = event.target.swimerize_password.value;
        if (password === undefined || password === "") {
            notification_store.notify_message("Missing password", "Please enter your password.");
            show_loading_indicator = false;
            return false;
        }

        let form_data = new FormData();
        form_data.append('email', email);
        form_data.append('password', password);

        let response = await fetch(`/auth/login/credentials`, {
            method: 'POST',
            body: form_data,
        });

        // console.log("Response from credentials login: ", response.status, response.headers.get('Location'));

        if (response.status === 400 || response.status === 500) {
            let payload = await response.json();
            notification_store.notify_message("Login failed", payload.message);
            show_loading_indicator = false;
            return false;
        }

        let response_payload = await response.json();
        let redirect_url = response_payload.location ?? '/feed';
        window.location.href = env_client.www_endpoint() + redirect_url;
    }

    function toggle_auth_modal() {
        show_login.set(!$show_login);
        reviewer_credentials_expanded = false;
        // menu_open = false;
        // profile_menu_open = false;
    }
</script>

{#if show_loading_indicator || $show_loading}
    <PreloadingIndicator />
{/if}

{#if $show_login}
    <div class="z-[100]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
        Background backdrop, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div transition:fade class="fixed inset-0 bg-primary transition-opacity"></div>

        <div class="fixed inset-0 z-50 overflow-y-auto">
            <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div transition:fade class="relative transform overflow-hidden shadow-2xl rounded-3xl bg-backdrop-light px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="absolute top-0 right-0 block pt-4 pr-4">
                    <button onclick={toggle_auth_modal} onkeypress={toggle_auth_modal} type="button" class="rounded-md bg-backdrop-light text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: outline/x-mark -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                </div>
                <div class="flex flex-col items-center">
                    <!-- <h3 class="text-2xl font-medium text-gray-900 mb-8" id="modal-title">
                    Swimerize 
                    </h3> -->
                    <!-- <img class="w-24 block mx-auto mb-4" src="/logo/swimerize-icon-light-bg.svg" alt="Swimerize Logo" /> -->
                    <img class="h-9 block mx-auto my-8" src="/logo/swimerize-logo-light-bg.svg" alt="Swimerize Logo" />
                    <div class="text-sm font-normal text-primary-text dark:text-gray-400">
                        {#if reviewer_credentials_expanded}
                            <span class="font-bold">Log in</span> with your Swimerize credentials.
                        {:else}
                            <!-- <span class="font-bold">Sign up <span class="font-normal">or</span> Log In</span> 
                            with any of the following: -->
                            Proceed with any of the following:
                        {/if}
                    </div>
                </div>
                <ul class="my-4 space-y-3">

                    {#if reviewer_credentials_expanded === false}
                        
                        {#each auth_providers as auth_provider}
                                        
                            <li>
                                <div onclick={() => login_with_provider(auth_provider.provider)} 
                                    onkeypress={() => login_with_provider(auth_provider.provider)}
                                    role="button"
                                    tabindex="0" 
                                    class="cursor-pointer flex items-center p-3 text-base font-bold text-gray-900 bg-white rounded-lg border-slate-200 border-2">
                                    <div class="h-6 w-6">
                                    <img src="{auth_provider.icon_path}" class="h-6 {auth_provider.class_override}" alt="{auth_provider.label} logo"/>
                                    </div>
                                    <span class="flex-1 ml-3 whitespace-nowrap">{auth_provider.label}</span>
                                    <!-- <span class="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400">Popular</span> -->
                                </div>
                            </li>
                    
                        {/each}

                    {/if}

                    {#if features_framework_clientside.is_credentials_login_enabled()}

                        <li>
                            {#if reviewer_credentials_expanded}

                                <div class="flex min-h-full flex-col justify-center" in:slide>
                                    <!-- <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                                    <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign w/ Swimerize Credentials</h2>
                                    </div> -->
                                
                                    <!-- <div class="relative my-4">
                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div class="w-full border-t border-gray-300"></div>
                                    </div>
                                    <div class="relative flex justify-center">
                                        <span class="bg-backdrop-light px-2 text-sm text-gray-500">or reviewer credentials</span>
                                    </div>
                                    </div> -->

                                    <div class="sm:mx-auto sm:w-full sm:max-w-sm">

                                        <form class="space-y-4" onsubmit={handle_credentials_login} method="POST">

                                            <div>
                                                <label for="swimerize_email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                                <div class="mt-1">
                                                    <input id="swimerize_email" name="swimerize_email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                                                </div>
                                                </div>
                                        
                                                <div>
                                                <div class="flex items-center justify-between">
                                                    <label for="swimerize_password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                                                </div>
                                                <div class="mt-1">
                                                    <input id="swimerize_password" name="swimerize_password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                                                </div>
                                            </div>
                                    
                                            <div>
                                                <button type="submit" 
                                                        class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                                                    Sign in
                                                </button>
                                            </div>
                                        </form>
                                
                                    </div>
                                </div>

                            {:else}

                                <button onclick={() => reviewer_credentials_expanded = true} 
                                        onkeypress={() => reviewer_credentials_expanded = true} 
                                        type="button" 
                                        class="flex w-full place-content-center place-items-center bg-backdrop-light px-3 py-1.5 text-xs leading-6 text-primary-text-diminished">
                                    Reviewer signin
                                    <Icon type={'expand_dropdown'} class="h-3.5 w-3.5 ml-2" />
                                </button>
                            {/if}
                        </li>
                    {/if}
                </ul>
                </div>
            </div>
        </div>
    </div>
{/if}

<!-- <div in:fade="{{ duration: 500 }}" out:fade class="fixed inset-0 z-[999] bg-primary flex place-items-center place-content-center"> 

    <div class="border rounded-lg bg-white p-4">
        Hello
    </div>
</div> -->
