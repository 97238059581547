import { intervalToDuration } from "date-fns";
import type { DistanceUnit } from "./distance_units";
import { METER_TO_KILOMETER_SCALAR, METER_TO_MILE_SCALAR, METER_TO_YARD_SCALAR } from "./dto/pace/PaceData";

export function displayable_duration(start_timestamp: Date, 
                                     stop_timestamp: Date, 
                                     compressed: boolean = false, 
                                     include_leading_hour: boolean = true,
                                     include_trailing_zero_seconds: boolean = true) {

    let duration = intervalToDuration({start: start_timestamp, end: stop_timestamp});
    let to_return = "";

    if (!compressed) {

        // if (!pad) {
            
            if (duration.hours !== undefined) {
                to_return += duration.hours + "h ";
            }

            if (duration.minutes !== undefined || to_return.length > 0) {
                to_return += (duration.minutes ?? 0) + "m ";
            }

            if (duration.seconds !== undefined || to_return.length > 0) {

                let seconds = duration.seconds ?? 0; 
                if (include_trailing_zero_seconds === true || seconds > 0) {
                    to_return += seconds + "s";
                }
            }

        // } else {

            // if (duration.hours !== undefined) {
            //     to_return += duration.hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + "h ";
            // } else {
            //     to_return += "0h ";
            // }
    
            // if (duration.minutes !== undefined) {
            //     to_return += duration.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + "m ";
            // } else {
            //     to_return += "0m ";
            // }
    
            // if (duration.seconds !== undefined) {
            //     to_return += duration.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + "s";
            // } else {
            //     to_return += "0s";
            // }
    
        // }

    } else {//if (compressed) {

        if (include_leading_hour) {

            if (duration.hours !== undefined) {
                to_return += duration.hours.toLocaleString('en-US', {minimumIntegerDigits: 1, useGrouping: false}) + ":";
            } else {
                to_return += "0:";
            } 
        }

        if (duration.minutes !== undefined) {
            to_return += duration.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ":";
        } else {
            to_return += "00:";
        }

        if (duration.seconds !== undefined) {
            to_return += duration.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        } else {
            to_return += "00";
        }    
    }

    return to_return.trim();
}

function build_observer_promise(component: HTMLElement | undefined, 
                                position: "start" | "center" | "end" = "center")
    : Promise<void> {

    if (!component) {
        return Promise.resolve();
    }

    return new Promise((resolve) => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                console.log('Scroll has completed');
                observer.disconnect(); // Stop observing
                resolve(); // Resolve the promise
            }
            });
        });
    
        observer.observe(component);
        component?.scrollIntoView({behavior: "smooth", block: position, inline: position});
    });    
}

export function scroll_component_into_view(component_id: string, position: "start" | "center" | "end" = "center")
    : Promise<void> {

    let component = document.getElementById(component_id);
    console.log("scrolling to component: ", component_id, component);

    return build_observer_promise(component ?? undefined, position);
};  

export function displayable_distance(distance_in_meters: number, 
                                     distance_unit: DistanceUnit,
                                     decimals_to_show: number = 2) {

    let distance_string;
    if (distance_unit === 'm') {
        distance_string = `${Math.round(distance_in_meters).toLocaleString()}`;
    } else if (distance_unit === 'yds') {
        distance_string = `${Math.round((distance_in_meters * METER_TO_YARD_SCALAR)).toLocaleString()}`;
    } else if (distance_unit === 'km') {
        distance_string = `${(distance_in_meters * METER_TO_KILOMETER_SCALAR).toFixed(decimals_to_show)}`;
    } else if (distance_unit === 'mi') {
        distance_string = `${(distance_in_meters * METER_TO_MILE_SCALAR).toFixed(decimals_to_show)}`;
    } else {
        distance_string = `${(distance_in_meters).toLocaleString()}`;
    }

    return distance_string;
}

/**
 * Unsafe function that creates a non-cryptographically secure random id.
 * NOTE: This method is meant to be used by low-stakes id de-collisioning.
 */
export function random_id(): string {

    const MAX_RANGE = 1_000_000_000_000;
    return `id-${Math.floor(Math.random() * MAX_RANGE)}`;
}
