<script lang="ts">

  interface Props {
    animate?: boolean;
    icon_class_override: any;
  }

  let { animate = true, icon_class_override }: Props = $props();
    
  </script>
  
<img src="/logo/swimerize-icon-light-bg.svg" 
    alt="logo" 
    class="{icon_class_override}" 
    class:animate-rubber-band={animate}
    style="animation-iteration-count: infinite; animation-duration: 1500ms"/>
    <!-- animate-squeeze animate-rubber-band -->
