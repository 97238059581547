<script lang="ts">

interface Props {
        icon: import('svelte').Snippet;
        label?: string;
        onclick?: () => void;
        highlighted: boolean;
    }

    let {
        icon,
        label = undefined,
        onclick = async () => {},
        highlighted = $bindable(false)
    }: Props = $props();

</script>

<button class:text-primary={highlighted} 
        class:text-primary-foreground-disabled={highlighted === false}
        {onclick}>
    {@render icon()}
</button>
