<script lang="ts">
	import { afterNavigate } from "$app/navigation";
	import { is_applehealthkit_authorized, process_latest_applehealthkit_activity_data, supports_apple_healthkit } from "$lib/apple_healthkit/apple_healthkit";
	import env_client from "$lib/env/env_clientside";
	import { get_session } from "$lib/session/session_initializer.svelte";
	// import { notification_store } from "$lib/stores/notification_store";


    let {user_settings, user_account_uuid} = get_session();

    const APPLEHEALTHKIT_CHECK_INTERVAL_DURATION = env_client.is_dev() ? 1000 * 10 : 1000 * 60 * 15; // Dev: 10s, Prod: 15 mins
    let applehealthkit_last_check_timestamp: Date;
    let user_logged_in = user_settings?.enabled ?? false;

    /**
     * Periodically invoke status checks.
    */
    afterNavigate(async () => {

        if (user_logged_in === false) {
            return;
        }

        let current_timestamp = new Date();

        /*
         * *******************
         * * Apple HealthKit *
         * *******************
         * 
         * Capacitor plugin currently does not support 'data update' callbacks (ie. 'push' strategy). 
         * Therefore will need to implement a 'pull' strategy for detecting new entries.
         */  
        let recently_checked = 
            applehealthkit_last_check_timestamp !== undefined 
            && current_timestamp.getTime() - applehealthkit_last_check_timestamp.getTime() <= APPLEHEALTHKIT_CHECK_INTERVAL_DURATION;
        if (!recently_checked) {

            applehealthkit_last_check_timestamp = new Date();

            let is_apple_healthkit_supported = await supports_apple_healthkit();
            if (is_apple_healthkit_supported && (await is_applehealthkit_authorized())) {

                await process_latest_applehealthkit_activity_data(user_account_uuid!);
            }
        }
    });

</script>