<script lang="ts">
	import type { AlertDTO } from "$lib/server/entities/alert/AlertEntity";

    interface Props {
        item: AlertDTO;
    }

    let { item }: Props = $props();

    let creator_user_account = item.created_by_user_account;
    let group = item.target_group!;

</script>

<a href="/groups/{group.uuid}/view" class="flex-1 text-xs leading-6">
    <div class="mb-1.5 text-gray-400"> 
        <span class="font-semibold text-primary-text-diminished">
            {creator_user_account.name}
        </span>
        invited you to join group 
        <span class="font-semibold text-primary-text-diminished">
        {group.name}.
    </div>
</a>
