<!--
    For details on how reactivity works w/ batching and updating values within a function - 
    read: https://stackoverflow.com/questions/63934543/svelte-reactivity-not-triggering-when-variable-changed-in-a-function

-->
<script lang="ts">
	import { onDestroy, onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import Spinner from './Spinner.svelte';

    let pause_timeout = 1000; // this needs to be large enough that increment animation can execute, otherwise nothing will be rendered
    let interval_id: any = undefined;
	let percentage_completed = $state(5);
    let increment_step = 5;
    const slowdown_trigger_point = 75;

    onMount(() => {

        // console.log("Starting initial timer.");
        interval_id = setInterval(() => {

            if (percentage_completed >= slowdown_trigger_point) {
                
                let remainder = 100 - percentage_completed;

                // increment slows down by half on each iteration (Dichotomy paradox)
                if (remainder <= 10) {
                    increment_step = remainder / 2;
                }

                // console.log("Increment step slowing down: ", increment_step);
            }

            percentage_completed = Math.round(percentage_completed + increment_step);

            // console.log("Timer running! ", percentage_completed);
        }, pause_timeout);
   	});

    onDestroy(() => {
        if (interval_id !== undefined) {
            clearInterval(interval_id);
        }
    })
</script>

<div in:fade="{{ duration: 500 }}" out:fade class="print:hidden fixed top-0 left-0 w-full h-1 z-[999]"> 
	<div class="absolute left-0 top-0 h-full bg-primary transition-all ease-in-out delay-500 pointer-events-none" style="width: {percentage_completed}%">
    </div> 
</div>
<div in:fade={{ duration: 150 }} 
     out:fade={{ duration: 500 }} 
     onclick={(event) => {event.preventDefault();return false;}} 
     onkeypress={(event) => {event.preventDefault();return false;}} 
     role="none"
     class="print:hidden fixed top-0 left-0 w-full h-full bg-backdrop-dark/90 pointer-events-none z-[998] flex flex-col">
     
    <Spinner />
</div> 
