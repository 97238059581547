<script lang="ts">
    import { page } from '$app/state';

    interface Props {
        popup_menu: boolean;
        url: string;
        text: string;
    }

    let { popup_menu, url, text }: Props = $props();

    let selected = $derived(page.url.pathname.endsWith(url));

</script>

{#if popup_menu}
    <a href="{url}" class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50">{text}</a>
{:else}
    <a href="{url}" class="{selected ? 'bg-slate-700 rounded-lg' : ''} p-2 m-2 text-base font-medium text-white hover:text-gray-300">{text}</a>
{/if}