import { type IDBPDatabase, openDB, type DBSchema } from 'idb';

export type SettingKey = 'apple_healthkit_latest_sync_timestamp' 
                         | 'device_activity_sync_processor_status' 
                         | 'approved_analytics'
                         | 'group_candidates_dismissed_uuids'
                         | 'user_candidates_dismissed_uuids'
                         | 'account_deletion_requested_timestamp'
                         | 'feed_device_sync_cta_visible'
                         | 'share_follow_link_cta_visible'
                         | 'dismissed_group_autoshare_banners'
                         | 'tmp_workout'
                         | 'workout_intensity_dropdown_category';

export type PersistentSettingsEntry = {key: SettingKey, 
                                       value_boolean?: boolean, 
                                       value_timestamp?: Date, 
                                       value_string?: string,
                                       value_array_string?: string[],
                                       last_update_timestamp: Date};

interface PersistentDatabase extends DBSchema {
    settings: {
        value: PersistentSettingsEntry;
        key: string;
    }
}

const PERSISTANT_DATABASE_NAME = 'settings_database';
const PERSISTANT_DATABASE_VERSION = 1;

class SettingsStore {

    private db?: IDBPDatabase<PersistentDatabase>;

    private async open_idb(): Promise<IDBPDatabase<PersistentDatabase>> {

        if (this.db === undefined) {

            this.db = await openDB<PersistentDatabase>(PERSISTANT_DATABASE_NAME, PERSISTANT_DATABASE_VERSION, {
                upgrade(upgrade_db) {
        
                    if (!upgrade_db.objectStoreNames.contains('settings')) {
                        upgrade_db.createObjectStore('settings', {keyPath: 'key'});
                    }
                }
            });
        }

        return this.db;
    }

    async get_setting(key: SettingKey): Promise<PersistentSettingsEntry | undefined> {

        const db = await this.open_idb();
        let entry: PersistentSettingsEntry | undefined = 
            await db.get('settings', key);

        return entry;
    }

    async put_setting(key: SettingKey, value: Partial<PersistentSettingsEntry>) {

        if (value === undefined) {
            return;
        }

        let to_store = {...value, key, last_update_timestamp: new Date()};
        // console.log("-----------> ", JSON.stringify(to_store));
        const db = await this.open_idb();
        await db.put('settings', to_store);
    }

    async delete_setting(key: SettingKey) {

        const db = await this.open_idb();
        await db.delete('settings', key);
    }
}

const settings_store = new SettingsStore();
export default settings_store;