<script lang="ts">
  	import { createEventDispatcher } from "svelte";
    import { page } from '$app/state';

    const dispatch = createEventDispatcher();
    function handleClick(event:any) {

        dispatch('menu-item-click');
    }

  interface Props {
    icon_svg_path: string;
    url: string;
    label: string;
    mobile: boolean;
    disable_selected_highlight?: boolean;
  }

  let {
    icon_svg_path,
    url,
    label,
    mobile,
    disable_selected_highlight = false
  }: Props = $props();

    let selected = $derived(page.url.pathname === url && !disable_selected_highlight);

</script>

{#if mobile}

  <a href="{url}" onclick={handleClick} class:bg-slate-700={selected} class="group flex items-center rounded-md p-2 text-base font-medium text-slate-300 hover:bg-gray-50 hover:text-gray-900">

      <!-- Heroicon name: outline/X -->
      <svg class="mr-4 h-6 w-6 text-slate-300 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="{icon_svg_path}" />
      </svg>
      {label}
  </a>

{:else}

  <a href="{url}" onclick={handleClick} class:bg-slate-700={selected} class="flex items-center rounded-lg p-4 text-indigo-200 hover:bg-indigo-700">

      <!-- Heroicon name: outline/X -->
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="{icon_svg_path}" />
      </svg>
      <span class="sr-only">{label}</span>
  </a>

{/if}


