<script lang="ts">
    interface Props {
        url: string;
        text: string;
        svg_path_data?: string | undefined;
        children?: import('svelte').Snippet;
    }

    let {
        url,
        text,
        svg_path_data = undefined,
        children
    }: Props = $props();
</script>

<a href="{url}" target="_blank" class="text-gray-400 hover:text-white">
    <span class="sr-only">{text}</span>
    <svg class="h-4 w-4 sm:h-6 sm:w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
      {#if children}{@render children()}{:else}
        <path fill-rule="evenodd" d="{svg_path_data}" clip-rule="evenodd" />
      {/if}
    </svg>
</a>
