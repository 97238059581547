<script lang="ts">
	import { is_feature_enabled, SUBSCRIPTION_DATE_RESTRICTION_DAYS_LOOKBACK } from '$lib/features_utils';
	import { subscription_store } from '$lib/stores/subscription_products_store';
	import { onDestroy } from 'svelte';

  interface Props {
    show_price?: boolean;
  }

  let { show_price = true }: Props = $props();
    let monthly_price: string | undefined = $state(undefined);
    let product_name: string | undefined = undefined;
    let remove_callback = subscription_store.subscribe(value => {

        let product_item = value.state.products.find(product => product.billing_period === 'monthly');
        // console.log('product_items: ', value.state.products);
        if (product_item === undefined) {

            console.warn('No monthly product found!');
            return;
        }

        monthly_price = product_item.price;
        product_name = product_item.name;
    });

    onDestroy(remove_callback);

    const PRICING_TABLE: 
        {
            name: string, 
            features: {
                        name: string, 
                        free?: boolean, 
                        subscription: boolean, 
                        free_note?: string
                      }[]
        }[] = [

        {
            name: 'Social',
            features: [
                {name: 'Friend network', free: true, subscription: true},
                {name: 'Engage in conversations', free: true, subscription: true},
                {name: 'Join groups & clubs', free: true, subscription: true},
                {name: 'Lead new discussions', 
                    free: is_feature_enabled('discussion.create', 'freemium'), 
                    subscription: is_feature_enabled('discussion.create', 'paying')},
                {name: 'Share pictures', 
                    free: is_feature_enabled('picture.upload', 'freemium'), 
                    subscription: is_feature_enabled('picture.upload', 'paying')},
                {name: 'Create groups & clubs', 
                    free: is_feature_enabled('group.create', 'freemium'), 
                    subscription: is_feature_enabled('group.create', 'paying')},
                {name: 'View group leaderboards', 
                    free: is_feature_enabled('group.leaderboard.view', 'freemium'), 
                    subscription: is_feature_enabled('group.leaderboard.view', 'paying')},
                {name: 'Public profile', 
                    free: is_feature_enabled('profile.visible.public', 'freemium'), 
                    subscription: is_feature_enabled('profile.visible.public', 'paying')},
            ]
        },

        {
            name: 'Activities',
            features: [
                {name: 'Activity device sync', free: true, subscription: true},
                {name: 'Open water swim details', free: true, subscription: true},
                {name: 'Pool swim details', free: true, subscription: true},
                {name: 'Realtime swim replays', subscription: true, free_note: 'Limited'},
                {name: 'Compare swim replays', subscription: true, free_note: 'Limited'},
            ]
        },

        {
            name: 'Analytics',
            features: [
                {name: 'Overview Dashboard', free: true, subscription: true},
                {name: 'Basic analytics', free: true, subscription: true},
                {name: 'Advanced analytics', free: false, subscription: true},
                // {name: 'Swolf analysis', 
                //     free: is_feature_enabled('activity.stats.swolf', 'freemium'), 
                //     subscription: is_feature_enabled('activity.stats.swolf', 'paying')},
                // {name: 'Stroke cadence analysis', 
                //     free: is_feature_enabled('activity.stats.stroke_count', 'freemium'), 
                //     subscription: is_feature_enabled('activity.stats.stroke_count', 'paying')},
                // {name: 'Stroke length analysis', 
                //     free: is_feature_enabled('activity.stats.stroke_length', 'freemium'), 
                //     subscription: is_feature_enabled('activity.stats.stroke_length', 'paying')},
                {name: 'Stroke trends analysis', 
                    free: is_feature_enabled('activity.stats.trends', 'freemium'), 
                    subscription: is_feature_enabled('activity.stats.trends', 'paying')},
            ]
        },

        {
            name: 'Workouts',
            features: [
                {name: 'View', free: true, subscription: true},
                {name: 'Create', free: true, subscription: true},
                {name: 'Print', free: true, subscription: true},

                // {name: 'Send to Device', free: false, subscription: true},
                // {name: 'Analytics', free: false, subscription: true},
                // {name: 'AI Auto-generate', free: false, subscription: true},
                ]
        }

    ];

  </script>
    
<table class="text-left w-full text-xs text-primary-foreground-enabled bg-backdrop-dark"> <!-- border-separate border-spacing-x-2 -->

    <caption class="sr-only">
        Pricing plan comparison
    </caption>
    <colgroup>
        <col class="w-1/2">
        <col class="">
        <col class="">
    </colgroup>

    <thead>
        <tr class="text-xs sticky -top-0.5 bg-backdrop-dark z-50">
            <th></th><!-- feature -->
            <th scope="col" class="px-6 pt-6 pb-3 align-top">Free</th>
            <th scope="col" class="px-6 pt-6 pb-3 align-top border-white/10 bg-white/5 flex flex-col place-items-center">
                {#if $subscription_store.initialized && show_price}
                    <div class="w-fit">
                        Full Access
                    </div>
                    {#if monthly_price}
                        <div class="relative mt-4 flex flex-col place-items-center gap-y-1 text-xl tracking-tight text-white">
                                {monthly_price}
                                <span class="pl-2 text-xs font-light text-primary-foreground-disabled">/month</span>
                        </div>
                    {/if}
                {:else}
                    Subscription
                {/if}
            <!-- <p>
                    <span class="flex-1">
                        <span class="text-slate-500 line-through text-lg">
                            $108
                        </span> 
                        <span class="text-xl">
                            $92
                        </span>
                        <span class="pl-1 text-xs">
                            /year
                        </span>
                    </span>
                </p>             -->
            </th>
        </tr>
    </thead>

    <tbody>

        {#each PRICING_TABLE as section, section_idx}
            <tr>
                <th scope="colgroup" class:pt-0={section_idx === 0} class:pt-8={section_idx !== 0} class="text-sm">
                    {section.name}
                    <div class="absolute inset-x-4 mt-2 h-px bg-white/10"></div>
                </th>
                <th scope="col" class="">
                </th>
                <th scope="col" class="border-white/10 bg-white/5"></th>
            </tr>

            {#each section.features as feature, feature_idx}

                <tr>
                    <th scope="row" class="pt-4 font-normal">
                        {feature.name}
                        <!-- {#if section_idx !== PRICING_TABLE.length - 1 && feature_idx !== PRICING_TABLE[PRICING_TABLE.length - 1].features.length - 1} -->
                            <div class="absolute inset-x-4 mt-2.5 h-0.5 bg-white/5"></div>
                        <!-- {/if} -->
                    </th>
                    <td class="pt-4">

                        {#if feature.free}
                            <svg class="mx-auto h-5 w-5 text-secondary-foreground-disabled" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                            </svg>
                        {/if}

                        {#if feature.free_note}
                            <p class="w-full text-[0.5rem] text-gray-400 text-center px-1">{feature.free_note}</p>
                        {/if}

                    </td>
                    <td class="pt-4 border-white/10 bg-white/5">
                        {#if feature.subscription}
                            <svg class="mx-auto h-5 w-5 text-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                            </svg>
                        {/if}
                    </td>
                </tr>
            {/each}
        {/each}
        <tr>
            <th scope="row" class="pt-4 text-xs font-light italic">
                <!-- <div class="absolute inset-x-4 mt-2.5 h-0.5 bg-white/5"></div> -->
                 ... and more
            </th>
            <td class="pt-4">

            </td>
            <td class="pt-4 border-white/10 bg-white/5">
            </td>
        </tr>
        <tr>
            <td class="pt-4"></td>
            <td></td>
            <td class="border-white/10 bg-white/5"></td>
        </tr>
    </tbody>
</table>
