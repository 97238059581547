<script lang="ts">
    import "../app.css";
    import { page, navigating } from '$app/state';
	import Header from "$lib/components/layout/header/Header.svelte";
	import Footer from "$lib/components/layout/footer/Footer.svelte";
	import PreloadingIndicator from "$lib/components/PreloadingIndicator.svelte";
	import PwaBootstrap from "$lib/components/PWABootstrap.svelte";
	import NativeBootstrap from "$lib/components/NativeBootstrap.svelte";
	import ToastNotifications from "$lib/components/ToastNotifications.svelte";
	import { onMount } from "svelte";
	import { notification_store } from "$lib/stores/notification_store.svelte";
    import { beforeNavigate } from '$app/navigation';
    import type { BeforeNavigate } from '@sveltejs/kit';
	import analytics from "$lib/client/analytics";
	import { Capacitor } from "@capacitor/core";
	import AppleHealthKitBootstrap from "$lib/components/apple_health_kit/AppleHealthKitBootstrap.svelte";
	import SubscriptionPopup from "$lib/components/subscription/SubscriptionPopup.svelte";
	import Particles from "$lib/components/Particles.svelte";
	import type { LayoutData } from "./$types";
	import { get_session, initialize_session } from "$lib/session/session_initializer.svelte";
	import type { UTMParams } from "$lib/utils";
	import BottomNavBar from "$lib/components/bottom_nav_bar/BottomNavBar.svelte";
	import sync_device_state from "$lib/stores/sync_device_state.svelte";

    interface Props {
        data: LayoutData;
        children?: import('svelte').Snippet;
    }

    let { children }: Props = $props();
    if (page.data.user_settings) {
        initialize_session(page.data.user_account_uuid, page.data.user_settings);
    }
    
    let is_ios: boolean = $state(false);
    let platform: string = $state('unknown');

    onMount(() => {

        // console.log("Root Layout (onMount) ------------> ", get_session());

        let url: URL = page.url;
        if (url && url.searchParams.get("forbidden") === 'true' || url.searchParams.get("error") !== null) {
            notification_store.notify_forbidden();
        }

        // console.log("Recording GA event...");
        // dataLayer.push({'event': 'push_notification'}); // GTM tracking
        // console.log("Finished recording GA event!");

        platform = Capacitor.getPlatform();
        is_ios = Capacitor.isNativePlatform() && platform === 'ios';

        // initialize sync device status
        sync_device_state.initialize(page.data.user_device_sync_data);
        // console.log("Finished initializing sync device state!", page.data.user_device_sync_data);
    });

    beforeNavigate(async (navigation: BeforeNavigate) => {

        let route_id = navigation.to?.route.id;
        if (route_id === undefined || route_id === null) {
            return;
        }

        // console.log("From: ", JSON.stringify(navigation.from), " - To: ", JSON.stringify(navigation.to), " - Type: ", navigation.type);

        let utm_tracking: UTMParams | undefined;
        if (navigation.to?.url) {

            let search_parameters = navigation.to.url.searchParams;
            let source = search_parameters.get('utm_source');
            let medium = search_parameters.get('utm_medium');
            let campaign = search_parameters.get('utm_campaign');
            let content = search_parameters.get('utm_content');
            
            if (source || medium || campaign) {
                utm_tracking = 
                    {
                        source: source !== null ? source : undefined, 
                        medium: medium !== null ? medium : undefined, 
                        campaign: campaign !== null ? campaign : undefined,
                        content: content !== null ? content : undefined
                    };
            }
        }

        await analytics.page_view(route_id, platform, utm_tracking);
    });

</script>


<Particles />

<!-- {#if user_settings?.analytics_enabled ?? false}
    <GoogleTagManager />
{/if} -->
<ToastNotifications />

<!-- <svelte:head>
    {@html webManifest}
</svelte:head> -->

{#if navigating.to}
	<PreloadingIndicator />
{/if}
<div class="bg-backdrop-light print:bg-white flex flex-col overflow-visible selection:bg-primary-enabled selection:text-fuchsia-100">

    <Header />
    
    <div class="px-0 flex-1 min-h-screen">
        {@render children?.()}
    </div>

    {#if get_session()}
        <BottomNavBar />
    {/if}

    <Footer />
</div>

<PwaBootstrap />
<NativeBootstrap />
{#if get_session()}

    {#if is_ios}
        <AppleHealthKitBootstrap />
    {/if}
    
    <SubscriptionPopup />
{/if}
