import env_client from "$lib/env/env_clientside";
import * as Sentry from '@sentry/sveltekit';


Sentry.init({
  dsn: env_client.sentry_dns(),
  environment: env_client.app_hostname(),
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  tracesSampleRate: 0, //env_client.is_dev() ? 0 : 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: env_client.is_dev() ? 0 : 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
      Sentry.replayIntegration(),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        formTitle: "We're listening",
        messagePlaceholder: "Did you run into an issue? Is there something you'd like to see?",
        submitButtonLabel: "Submit",
        successMessageText: "Thank you for your feedback!",
        colorScheme: "system",
        themeDark: {
            background: "rgb(24 24 27 / var(--tw-bg-opacity))",
            submitBackground: "rgb(192 38 211 / var(--tw-bg-opacity))",
            submitBorder: "transparent",
            submitOutlineFocus: "rgb(192 38 211 / var(--tw-bg-opacity))",
            inputOutlineFocus: "rgb(192 38 211 / var(--tw-bg-opacity))",
            success: "rgb(192 38 211 / var(--tw-bg-opacity))"
        },
        autoInject: false,
        showBranding: false,
        showName: false,
        showEmail: false,
      }),
    ],
});  

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
