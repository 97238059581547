import { decompressSync, compressSync, strFromU8, strToU8 } from 'fflate';
import md5 from 'blueimp-md5';
import { CoordinateData } from './dto/track/CoordinateData';
import type { Cookies } from '@sveltejs/kit';
import type { RequestLocation } from '../app';

export type UTMParams = Partial<{
    source: string,
    medium: string,
    campaign: string,
    content: string,
}>;

export const DEFAULT_COMPRESSION_ENCODING: string = "gzip";

/**
 * Given the result from Header.entries(), returns a RequestLocation instance populated from the
 * x-vercel-* request location headers.
 */
export function parse_headers_for_request_location(headers: Headers): RequestLocation {

    let ip_address: string | undefined;
    let country: string | undefined;
    let country_region: string | undefined;
    let city: string | undefined;
    let timezone: string | undefined;
    let lat: number = NaN;
    let lng: number = NaN;

    // From CoPilot: 
    // "TypeScript's type definitions for the Fetch API are not always up-to-date 
    //  with the latest changes in the Fetch specification."
    // Using 'any' to get around this.
    for (let entry of (headers as any).entries()) {

        let key = entry[0];
        let value = entry[1];

        if (key === 'x-real-ip') {
            ip_address = value;
        } else if (key === 'x-vercel-ip-country') {
            country = value;
        } else if (key === 'x-vercel-ip-country-region') {
            country_region = value;
        } else if (key === 'x-vercel-ip-city') {
            city = value;
        } else if (key === 'x-vercel-ip-latitude') {
            lat = parseInt(value);
        } else if (key === 'x-vercel-ip-longitude') {
            lng = parseInt(value);
        } else if (key === 'x-vercel-ip-timezone') {
            timezone = value;
        } 
    }

    let to_return: RequestLocation = {ip_address, country, country_region, city, timezone};
    if (lat && lng) {
        to_return = {
                        lat_lng: new CoordinateData(lat, lng),
                        ...to_return
                    };
    }

    return to_return;
}

/*
 * Taken from https://www.geekstrick.com/snippets/how-to-parse-cookies-in-javascript/
 */
export function parseCookie(cookie: string): {[key:string]: string} {

    let to_return =
        cookie
            .split(';')
            .map(v => v.split('='))
            .reduce((acc: {[key: string]: string}, v) => {
                acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
                return acc;
            }, {});

    // `pkg=math; equation=E%3Dmc%5E2` -> { pkg: 'math', equation: 'E=mc^2' }}
    return to_return;
}

export function encodeCookie(cookie: any) {

    let to_return = "";
    for (const name in cookie) {
        to_return += encodeURIComponent(name) + "=" + encodeURIComponent(cookie[name]) + ";";
    }

    return to_return;
}

export function compress_string(contents: string): Uint8Array {

    const array_buffer = strToU8(contents);
    let encoded_contents = compress_binary(array_buffer);

    return encoded_contents;
}

export function compress_binary(contents: Uint8Array): Uint8Array {

    // console.log("Before compression length: ", contents.byteLength);
    let to_return = compressSync(contents, { level: 7, mem: 8 });
    // console.log("After compression length: ", to_return.byteLength, contents.byteLength);

    return to_return;
}

export function decompress_to_string(encoded_contents: Uint8Array): string {

    const decompressed = decompressSync(encoded_contents);
    let contents = strFromU8(decompressed);

    return contents;
}

export function decompress_to_array_buffer(encoded_contents: Uint8Array): ArrayBuffer {

    const decompressed = decompressSync(encoded_contents);

    return decompressed.buffer;
}

export function get_optional_search_param(url: URL, param_name: string): any | undefined {

    let search_params: URLSearchParams = url.searchParams;
    let param_value = search_params.get(param_name);

    return param_value !== null ? param_value : undefined;
}

export function get_all_optional_search_param(url: URL, param_name: string): any[] | undefined {

    let search_params: URLSearchParams = url.searchParams;
    let param_value = search_params.getAll(param_name);

    return param_value !== null ? param_value : undefined;
}

export function to_array_buffer(contents: {binary?: ArrayBuffer, text?: string}): ArrayBuffer {
  
    let to_return: ArrayBuffer;
    if (contents.binary !== undefined) {
        // console.log("ArrayBuffer contents.");
        to_return = contents.binary;
    } else if (contents.text !== undefined) {
        // console.log("String contents.");
        to_return = (new TextEncoder).encode(contents.text).buffer;
    } else {
        throw new Error("Contents must be either binary or text.");
    }

    return to_return;
}


export function hash_md5_array_buffer(array_buffer: ArrayBuffer): string {

    let int_array = new Uint8Array(array_buffer);
    let decoder = new TextDecoder('utf-8');
    let text: string = decoder.decode(int_array); //String.fromCharCode.apply(null, int_array.);
    let hash = md5(text);
    
    // console.log("Hash: ", hash, 
    //             " Byte Length: ", array_buffer.byteLength, 
    //             " Uint Byte Length: ", int_array.byteLength);
    return hash;
}

export function hash_md5_string(string_content: string): string {

    return md5(string_content);
}

export function capitalize(str: string): string {

    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    
    return arr.join(" ");
}

export async function sleep(millis: number) {
    return new Promise(resolve => setTimeout(resolve, millis));
}

export function map_object_members<T>(obj: any, fn: (key: string, value: any) => any ): T {
    return Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], _) => [k, fn(k, v)]
        )
    ) as T;
}

/**
 * Returns an ordered array of Date objects representing exactly 'days_in_between' between start and end dates.
 * First item is start_date, last item is end_date, regardless of days_in_between.
 */
export function dates_in_between(start_date: Date, end_date: Date, days_in_between: number): Date[] {

    let to_return: Date[] = [];
    let current_timestamp_in_millis = start_date.getTime();
    let end_timestamp_in_millis = end_date.getTime();
    while (current_timestamp_in_millis < end_timestamp_in_millis) {
        
        to_return.push(new Date(current_timestamp_in_millis));
        
        current_timestamp_in_millis += 1000 * 60 * 60 * 24 * days_in_between;
    }

    to_return.push(end_date);

    return to_return;
}

/**
 * Creates pairs of dates from the output from function 'dates_in_between'.
 */
export function dates_in_between_pairs(start_date: Date, end_date: Date, days_in_between: number)
    : [Date, Date][] {

    let to_return: [Date, Date][] = [];
    let all_dates = dates_in_between(start_date, end_date, days_in_between);
    for (let i = 0; i < all_dates.length; i++) {

        if (i === all_dates.length - 1) {
            break;
        }
        
        to_return.push([all_dates[i], all_dates[i + 1]]);
    }

    return to_return;
}

/**
 * Sets a cookie.
 * 
 * @param cookies 
 * @param name 
 * @param value 
 * @param max_age_in_seconds Default is 10 minutes.
 */
export function set_cookie(cookies: Cookies, 
                           name: string, 
                           value: string, 
                           max_age_in_seconds: number = 60 * 10) {

    cookies.set(name, 
                value, 
                {
                    secure: true, 
                    path: "/",
                    httpOnly: true,
                    maxAge: max_age_in_seconds,
                    sameSite: "lax"
                });
}

/**
 * Fetch decorator implementation that attempts three retries.
 * 
 * @param url 
 * @param fetch 
 * @param max_retries 
 * @returns 
 */
export async function fetch_json_w_retry(url: URL, fetch: any, max_retries = 3) {

    let json_to_return;
    for (let i = 0; i < max_retries; i++) {

        try {
            let response = await fetch(url);
            if (response.ok) {
                json_to_return = await response.json();
                break;
            }
        } catch (error) {
            console.error(`Error fetching ${url.toString()}: ${error}... trying again (${i + 1} of ${max_retries})`);
        }
    }

    return json_to_return;
}
