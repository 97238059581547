import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0];

export const dictionary = {
		"/(landing)": [34],
		"/(landing)/auth": [35],
		"/(landing)/auth/callback/apple": [~36],
		"/(landing)/auth/callback/facebook": [~37],
		"/(landing)/auth/callback/google": [~38],
		"/(app)/courses": [7,[2]],
		"/(app)/c/[slug]": [~6,[2]],
		"/(app)/discussions/[uuid]/view": [~8,[2]],
		"/(landing)/faq": [39],
		"/(app)/feed": [~9,[2]],
		"/(app)/groups/new": [13,[2]],
		"/(app)/groups/[group_uuid]/edit": [~10,[2]],
		"/(app)/groups/[group_uuid]/invite": [~11,[2]],
		"/(app)/groups/[group_uuid]/view": [~12,[2]],
		"/(dashboard)/home": [~26,[4]],
		"/(dashboard)/home/settings": [~33,[4]],
		"/(dashboard)/home/[user_account_uuid]/friends": [27,[4]],
		"/(dashboard)/home/[user_account_uuid]/groups": [28,[4]],
		"/(dashboard)/home/[user_account_uuid]/profile": [~29,[4]],
		"/(dashboard)/home/[user_account_uuid]/swims": [~30,[4]],
		"/(dashboard)/home/[user_account_uuid]/swims/upload": [31,[4]],
		"/(dashboard)/home/[user_account_uuid]/swims/upload/finish": [~32,[4]],
		"/integrations/manage/apple/authorize/step1": [42],
		"/integrations/manage/garmin/authorize/step1": [~43],
		"/integrations/manage/garmin/authorize/step2": [~44],
		"/integrations/manage/garmin/authorize/step3": [~45],
		"/integrations/manage/garmin/deregister": [46],
		"/integrations/manage/setup": [47],
		"/(app)/onboarding/finalize": [~17,[2]],
		"/(app)/onboarding/(step)/step1": [14,[2,3]],
		"/(app)/onboarding/(step)/step2": [15,[2,3]],
		"/(app)/onboarding/(step)/step3": [~16,[2,3]],
		"/(app)/pools/[uuid]/view": [~18,[2]],
		"/(landing)/privacy": [40],
		"/(app)/share/[activity_type]/[uuid]": [~19,[2]],
		"/(app)/subscribe/restricted": [20,[2]],
		"/system/map": [48,[5]],
		"/(landing)/terms": [41],
		"/(app)/tracks/[uuid]/view": [~21,[2]],
		"/(app)/workouts": [22,[2]],
		"/(app)/workouts/new": [~25,[2]],
		"/(app)/workouts/[uuid]/edit": [~23,[2]],
		"/(app)/workouts/[uuid]/view": [~24,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';