import device_activity_sync_state_store, { type PersistentDeviceActivityStoreEntry } from "$lib/client/indexeddb/device_activity_sync_state_store";
import settings_store from "$lib/client/indexeddb/settings_store";
import { Capacitor } from "@capacitor/core";
import { SwimifiedCapacitorHealthKit, type WorkoutResults } from 'swimified-apple-healthkit';
import { activity_sync_store } from "$lib/stores/activity_sync_store";
import AppleHealthkitSyncWorker from "$lib/client/web_worker/apple_healthkit_sync.worker.ts?worker";
import { get_fixed_sync_process_state, is_ahk_incomplete, is_ahk_past_data_retention_threshold } from "./apple_healthkit_common";
import { dates_in_between_pairs } from "$lib/utils";
import { version } from "$app/environment";
import type { OriginalUploadSyncEntry } from "$lib/server/original_upload/original_upload_repository";
import type { OriginalUploadPartnerType } from "$lib/server/entities/original_upload/OriginalUploadEntity";
import env_client from "$lib/env/env_clientside";
// import { sleep } from "$lib/utils"; // AHK TESTING
// import crypto from 'crypto'; // AHK TESTING

const AHK_FETCH_BATCH_DAYS = 7;

export async function supports_apple_healthkit(): Promise<boolean> {

    // // AHK TESTING
    // return true;
    let is_available = await SwimifiedCapacitorHealthKit.is_available().then(() => {return true}).catch(() => {return false});

    return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios' && is_available;
}

export async function is_applehealthkit_authorized(): Promise<boolean> {

    // // AHK TESTING
    // return true;
    let latest_sync = await fetch_latest_sync_timestamp();

    // console.log("is_applehealthkit_authorized: ", latest_sync);

    return latest_sync !== undefined;
}

export function enqueue_service_worker_activity(uuids: string[]) {

    if (uuids.length === 0) {
        return;
    }

    // create web worker
    let sync_worker = new AppleHealthkitSyncWorker();

    // initialize store and kick off async process
    activity_sync_store.enqueue(uuids);
    sync_worker.postMessage({uuids, version});

    // listen for async events from web worker
    sync_worker.onmessage = (event) => {

        activity_sync_store.complete(event.data.processed_uuids);
    };
}

export async function process_applehealthkit_activity_data(user_account_uuid: string, 
                                                           start_date: Date, 
                                                           end_date: Date,
                                                           process_all_available = true)
    : Promise<PersistentDeviceActivityStoreEntry[]> {

    // alert(`Start: ${user_account_uuid}, ${start_date}, ${end_date}`);
    let results: PersistentDeviceActivityStoreEntry[] = [];

    // fetch orphaned 'processing' and 'available' entries to re-engage processing
    let orphaned_uuids: string[] = [];
    if (process_all_available) {
        
        let items = await device_activity_sync_state_store.list_activity_entries();
        items = items.filter(item => item.sync_status === 'available' || item.sync_status === 'processing');
        
        orphaned_uuids = items.map(item => item.uuid);
    }

    // fetch new entries to include
    let new_uuids_to_process: string[] = [];

    /*
     * AHK seems to have an issue when pulling too much data at once. It streams the data in a way
     * that is not yet supported by the plugin. As a result, we need to pull data in smaller chunks.
     * 
     * See: https://developer.apple.com/documentation/healthkit/hksamplequery/executing_sample_queries
     * 
     * This is a temporary workaround until the plugin is updated to support streaming.
     */
    const week_pairs = dates_in_between_pairs(start_date, end_date, AHK_FETCH_BATCH_DAYS);
    // alert("Week pairs: " + JSON.stringify(week_pairs));
    for (let week_pair of week_pairs) {

        let start_timestamp = week_pair[0];
        let end_timestamp = week_pair[1];

        let query_results: WorkoutResults | undefined = 
            await SwimifiedCapacitorHealthKit.fetch_workouts({start_date: start_timestamp, end_date: end_timestamp});

        // // AHK TESTING
        // results = [
        //     {
        //         activity_timestamp: new Date(),
        //         activity_end_timestamp: new Date(),
        //         creation_timestamp: new Date(),
        //         device_type: 'apple_healthkit',
        //         file_type: 'ahk',
        //         last_update_timestamp: new Date(),
        //         sync_status: 'available',
        //         user_account_uuid: "1111-2222-3333-4444",
        //         uuid: crypto.randomUUID(),
        //         entity_type: 'pool',
        //         entity_uuid: "aaaa-bbbb-cccc-dddd",
        //         error_message: undefined,
        //         payload: undefined
        //     }
        // ];
        // await device_activity_sync_state_store.put_activity_entry(results[0]);


        // console.log("Apple HealthKit results: ", query_results);
        // alert(`Results: ${query_results.count}`);

        for (let result of query_results.results) {

            /*
             * NOTE: We want to silently suppress any 'incomplete' AHK workouts that are older than the
             *       data retention threshold. This is to avoid processessing data that is not expected to
             *       be available.
             * 
             *       For cases where the data is incomplete but within the retention threshold, we want to
             *       let that go through normal processing flow so that an error can be generated serverside
             *       and investigated.  
             */
            let is_expired = is_ahk_incomplete(result) && is_ahk_past_data_retention_threshold(result);
            let payload: PersistentDeviceActivityStoreEntry = 
                {
                    uuid: result.uuid, 
                    user_account_uuid, 
                    file_type: 'ahk', 
                    payload: JSON.stringify(result), 
                    activity_timestamp: new Date(result.start_date),
                    activity_end_timestamp: new Date(result.end_date), 
                    creation_timestamp: new Date(), 
                    device_type: 'apple_healthkit', 
                    last_update_timestamp: new Date(), 
                    sync_status: is_expired ? 'unavailable' : 'processing',   
                };

            // store payload details for processing
            await device_activity_sync_state_store.put_activity_entry(payload);

            results.push(payload);

            if (!is_expired) {
                new_uuids_to_process.push(result.uuid);
            }
        }
    }

    // console.log("Apple HealthKit results: ", results.length);
    
    // kick-off sync'ing in service worker
    enqueue_service_worker_activity([...orphaned_uuids, ...new_uuids_to_process]); 

    return results ?? [];
}

async function is_sync_processor_available(): Promise<boolean> {

    let status = await get_fixed_sync_process_state();
    return status === 'idle';
}

async function sync_latest_upload_state_from_server(user_account_uuid: string, 
                                                    partner: OriginalUploadPartnerType)
    : Promise<Date | undefined> {

    if (partner !== 'apple') {
        throw new Error("Unsupported partner for local sync.");
    }

    const device_type = 'apple_healthkit';

    let latest_sync_timestamp: Date | undefined;

    // fetch last known sync by Apple serverside
    let endpoint_url = new URL('/integrations/sync.json', env_client.www_endpoint());
    endpoint_url.searchParams.append('partner', 'apple');

    let response = await fetch(endpoint_url);
    if (response.status === 200) {
        let response_json: {partner_uploads: OriginalUploadSyncEntry[]} = await response.json();

        // alert("Response: " + JSON.stringify(response_json));

        // populate internal sync state
        for (let item of response_json.partner_uploads) {

            let payload: PersistentDeviceActivityStoreEntry = 
            {
                uuid: item.source_id,
                user_account_uuid, 
                file_type: 'ahk', 
                // payload: JSON.stringify(result), 
                activity_timestamp: new Date(item.start_timestamp),
                activity_end_timestamp: new Date(item.end_timestamp), 
                creation_timestamp: new Date(item.creation_timestamp), 
                device_type, 
                last_update_timestamp: new Date(), 
                sync_status: 'processed',   
            };
    
            await device_activity_sync_state_store.put_activity_entry(payload);

            // determine latest sync time
            if (latest_sync_timestamp === undefined 
                || payload.activity_end_timestamp.getTime() > latest_sync_timestamp.getTime()) {

                latest_sync_timestamp = payload.activity_end_timestamp;
            }
        }

    } else {
        throw new Error("Unable to fetch latest sync time for Apple HealthKit integration.");
    }

    return latest_sync_timestamp
}

export async function process_applehealthkit_activity_data_for_date_range(user_account_uuid: string, 
                                                                          start_date: Date, 
                                                                          end_date: Date)
    : Promise<boolean> {

    // proceed iff service worker 'idle'
    let sync_processor_available = await is_sync_processor_available();
    if (!sync_processor_available) {
        return false;
    }

    await process_applehealthkit_activity_data(user_account_uuid, start_date, end_date, false);

    return true;
}

export async function process_latest_applehealthkit_activity_data(user_account_uuid: string): Promise<boolean> {

    // proceed iff service worker 'idle'
    let sync_processor_available = await is_sync_processor_available();
    if (!sync_processor_available) {
        return false;
    }

    // fetch starting boundary
    let latest_sync_timestamp: Date | undefined = await fetch_latest_sync_timestamp();
    if (latest_sync_timestamp === undefined) {

        latest_sync_timestamp = await sync_latest_upload_state_from_server(user_account_uuid, 'apple');
        if (latest_sync_timestamp === undefined) {

            // fallback to start of time
            if (latest_sync_timestamp === undefined) {
                console.log("No last-known sync time found server-side.");
                latest_sync_timestamp = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 365 * 10)); // 10 years ago
            }
        }
    }

    // set fetch boundaries to last successful fetch or beginning of time
    let start_date: Date = latest_sync_timestamp;
    let end_date: Date = new Date();

    // TODO: For testing ONLY!!!!!
    // if (true) {
    //     start_date = new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 365 * 10));
    //     end_date = new Date('2023-08-01');
    // }
    // alert(`Start: ${start_date}, End: ${end_date}`);

    await process_applehealthkit_activity_data(user_account_uuid, start_date, end_date);

    await settings_store.put_setting('apple_healthkit_latest_sync_timestamp', {value_timestamp: end_date});

    return true;
}

export async function authorize_apple_healthkit(user_account_uuid: string) {

    // console.log("Apple HealthKit availability: ", 
    //             Capacitor.getPlatform(), 
    //             await SwimifiedCapacitorHealthKit.is_available().then(() => {return true}).catch(() => {return false}));

    await SwimifiedCapacitorHealthKit.request_permissions();

    /*
        * 'Latest sync timestamp' is the way we mark whether or not authorization has been granted.
        */
    let latest_sync_timestamp = await fetch_latest_sync_timestamp();
    if (latest_sync_timestamp === undefined) {

        // alert("About to fetch latest state from db...");
        latest_sync_timestamp = await sync_latest_upload_state_from_server(user_account_uuid, 'apple');
        // alert("Fetched latest state from db: " + latest_sync_timestamp);

        if (latest_sync_timestamp === undefined) {
            console.log("Apple HealthKit never been authorized before - authorizing.");
            latest_sync_timestamp = new Date(1000 * 60 * 60 * 24 * 365 * 10); // 10 years ago
        }

        await settings_store.put_setting('apple_healthkit_latest_sync_timestamp', 
                                         {value_timestamp: latest_sync_timestamp});
        console.log("Apple HealthKit authorization recorded.");
    }
}

export async function fetch_latest_sync_timestamp(): Promise<Date | undefined> {

    let entry = await settings_store.get_setting('apple_healthkit_latest_sync_timestamp');
    return entry?.value_timestamp;
}

export async function list_sync_state(): Promise<PersistentDeviceActivityStoreEntry[]> {

    // // AHK TESTING
    // let seed_results: PersistentDeviceActivityStoreEntry[] = [
    //     {
    //         activity_timestamp: new Date(),
    //         activity_end_timestamp: new Date(),
    //         creation_timestamp: new Date(),
    //         device_type: 'apple_healthkit',
    //         file_type: 'ahk',
    //         last_update_timestamp: new Date(),
    //         sync_status: 'processed',
    //         user_account_uuid: "1111-2222-3333-4444",
    //         uuid: "5555-6666-7777-8888",
    //         entity_type: 'pool',
    //         entity_uuid: "aaaa-bbbb-cccc-dddd",
    //         error_message: undefined,
    //         payload: undefined
    //     },
    //     {
    //         activity_timestamp: new Date(),
    //         activity_end_timestamp: new Date(),
    //         creation_timestamp: new Date(),
    //         device_type: 'apple_healthkit',
    //         file_type: 'ahk',
    //         last_update_timestamp: new Date(),
    //         sync_status: 'failed',
    //         user_account_uuid: "1111-2222-3333-4444",
    //         uuid: "5555-6666-7777-8888",
    //         entity_type: 'pool',
    //         entity_uuid: "aaaa-bbbb-cccc-dddd",
    //         error_message: "Unexpected error when uploading!",
    //         payload: undefined,
    //         s3_key: "/2023/06/02/1234-1234-1234-1223.ahk"
    //     },
    //     {
    //         activity_timestamp: new Date(),
    //         activity_end_timestamp: new Date(),
    //         creation_timestamp: new Date(),
    //         device_type: 'apple_healthkit',
    //         file_type: 'ahk',
    //         last_update_timestamp: new Date(),
    //         sync_status: 'available',
    //         user_account_uuid: "1111-2222-3333-4444",
    //         uuid: "5555-6666-7777-8888",
    //         entity_type: 'pool',
    //         entity_uuid: "aaaa-bbbb-cccc-dddd",
    //         error_message: undefined,
    //         payload: undefined
    //     },
    //     {
    //         activity_timestamp: new Date(),
    //         activity_end_timestamp: new Date(),
    //         creation_timestamp: new Date(),
    //         device_type: 'apple_healthkit',
    //         file_type: 'ahk',
    //         last_update_timestamp: new Date(),
    //         sync_status: 'processing',
    //         user_account_uuid: "1111-2222-3333-4444",
    //         uuid: "5555-6666-7777-8888",
    //         entity_type: 'pool',
    //         entity_uuid: "aaaa-bbbb-cccc-dddd",
    //         error_message: undefined,
    //         payload: undefined
    //     }
    // ];  
    // let results: PersistentDeviceActivityStoreEntry[] = [];
    // for (let item of seed_results) {
    //     let existing = await device_activity_sync_state_store.get_activity_entry(item.uuid);
    //     if (!existing) {
    //         await device_activity_sync_state_store.put_activity_entry(item);
    //         results.push(item);
    //     } else {
    //         results.push(existing);
    //     }
    // }

    let results = await device_activity_sync_state_store.list_activity_entries();

    results.sort((a: PersistentDeviceActivityStoreEntry, b: PersistentDeviceActivityStoreEntry) => b.activity_timestamp.getTime() - a.activity_timestamp.getTime()); 

    return results;
}

export async function has_unprocessed_activities(): Promise<boolean> {

    let state = await list_sync_state();
    return state.filter(item => item.sync_status === 'available' || item.sync_status === 'processing').length > 0;

}