<script lang="ts">
	import PleaseWaitSpinner from "./PleaseWaitSpinner.svelte";

    interface Props {
        children?: import('svelte').Snippet;
        label?: string;
        onclick?: () => Promise<void>;
        submit?: boolean;
        form_action?: string;
        enabled?: boolean;
        primary?: boolean;
        class?: string;
        enable_spinner?: boolean;
        spinner_class_override?: string;
        spinner_color?: 'primary' | 'inverse';
        spinner_message?: string;
        force_spinner?: boolean;
    }

    let {
        children,
        label = undefined,
        onclick = async () => {},
        submit = false,
        form_action = undefined,
        enabled = $bindable(true),
        primary = true,
        class: class_override,
        /**
         * This will replace the button contents with a spinner and disable the button while the on_click function is running.
         */
        enable_spinner = false,
        spinner_class_override = "",
        spinner_color = 'inverse',
        spinner_message = '',
        /**
         * Force the spinner to be shown. This is useful if you want to control the state of the spinner externally through a bind operation.
         */
        force_spinner = $bindable(false)
    }: Props = $props();

    let processing = $state(false);
    function process() {

        processing = true;
        onclick().then(() => {processing = false;});
    }

</script>

<button type={submit ? "submit" : "button"} 
        onclick={process} 
        class="{enabled && primary ? 'bg-primary-enabled text-white focus:outline-none focus:ring-2 focus:ring-primary-enabled focus:ring-offset-2': enabled === false ? 'bg-primary-disabled text-primary-foreground-disabled' : 'bg-primary-disabled text-primary-text'} inline-flex items-center rounded-md border border-transparent px-3 sm:px-4 py-2 text-sm font-medium shadow-sm {class_override}"
        disabled={processing || !enabled}
        formaction={form_action}>


    {#if (enable_spinner && processing) || force_spinner}
        <PleaseWaitSpinner class={spinner_class_override} {spinner_color} message={spinner_message}/>
    {:else}
        {#if children}
            {@render children()}
        {/if}
        {#if label}
            {label}
        {/if}
    {/if}
</button>
