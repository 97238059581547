import env_client from "$lib/env/env_clientside";
import type { BillingSystem, SubscriptionStatus } from "$lib/server/entities/subscription/SubscriptionEntity";
import type { AccountSubscriptionStatus } from "$lib/server/entities/user_account/UserAccountEntity";
import { subscription_store } from "$lib/stores/subscription_products_store";

export type Product = {
    product_id: string;
    offer_id: string;
    price: string; // includes currency character
    name: string;
    description: string;
    token: string;
    billing_period: BillingPeriod;
};

export type Subscription = {

    product_id: string;
    expiration_date: Date;
    active: boolean;
    token?: string; // temporarily present during activation flow
};
export type SubscriptionState = {

    products: Product[]
    subscriptions: Subscription[];
};

export type BillingPeriod = 'monthly' | 'yearly';

const DISPLAY_ERROR_DURATION_IN_MILLIS = 10000;

namespace GooglePlay {

    export const product_ids = ["swimerize.subscription.full_access"];

    export function parse_billing_period(billing_period: string): BillingPeriod {

        return billing_period === 'P1M' ? 'monthly' : 'yearly';
    }
}

namespace AppleAppStore {

    export const product_ids = [
                            env_client.subscription_products().monthly,
                            env_client.subscription_products().yearly
                        ];
}

function log(platform: CdvPurchase.Platform, message: string) {

    if (env_client.is_dev() === false) {
        return;
    }

    if (platform === CdvPurchase.Platform.APPLE_APPSTORE) {
        // alert(message);
        console.log(message);
    } else {
        console.log(message);
    }
}

export function initialize_client_subscription_service(platform: 'ios' | 'android' | 'web', 
                                                       subscription_status: AccountSubscriptionStatus) {

    if (platform === 'web') {
        return;
    }
        
    client_subscription_service.initialize(
        platform === 'android' ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE, 
        subscription_status);
}

export function is_payment_issue_status(status: SubscriptionStatus) {

    return status === 'pending' || status === 'grace_period' || status === 'hold';
}

function parse_registered_products_from_store(store: CdvPurchase.Store)
    : SubscriptionState {

    const products_payload: CdvPurchase.Product[] = store.products;
    const verified_purchases_payload: CdvPurchase.VerifiedPurchase[] = store.verifiedPurchases;
    const platform = store.defaultPlatform();                   

    return parse_registered_products_payload(products_payload, verified_purchases_payload, platform, store);
}

export function parse_registered_products_payload(products_payload: CdvPurchase.Product[],
                                                  verified_purchases_payload: CdvPurchase.VerifiedPurchase[],
                                                  platform: CdvPurchase.Platform,
                                                  store?: CdvPurchase.Store)
    : SubscriptionState {

    // process products
    let products: Product[] = [];
    for (let product of products_payload) {

        //log(platform, `Product: ${JSON.stringify(product)}`);

        let product_id = product.id;
        let is_valid_product_id; 
        if (platform === CdvPurchase.Platform.GOOGLE_PLAY) {
            is_valid_product_id = GooglePlay.product_ids.includes(product_id);
        } else if (platform === CdvPurchase.Platform.APPLE_APPSTORE) {
            is_valid_product_id = AppleAppStore.product_ids.includes(product_id);
        } else {
            throw new Error(`Unsupported billing platform - ${platform}`);
        }
        if (!is_valid_product_id) {
            continue;
        }

        let name = product.title;
        let description = product.description;
        
        for (let offer of product.offers) {

            let pricing_phases = offer.pricingPhases;
            if (pricing_phases.length !== 1) {
                continue;
            }

            let pricing_phase = pricing_phases[0];
            let price = pricing_phase.price;
            let billing_period = GooglePlay.parse_billing_period(pricing_phase.billingPeriod!);
            let token = (offer as any).token;

            let offer_id = offer.id;

            products.push({
                product_id,
                offer_id,
                price,
                name,
                description,
                token,
                billing_period
            });
        }
    }

    // process verified purchases
    let subscriptions: Subscription[] = [];
    if (verified_purchases_payload.length > 0 && store !== undefined) {

        const active_subscription = verified_purchases_payload.find(purchase => {
            const product = store.get(purchase.id, purchase.platform);
            let found = product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION && product.owned;

            log(platform, `-> ${found} ${JSON.stringify(purchase)} ${JSON.stringify(product)}`);
            return found;
        });

        // no active one, show info about the expired one
        if (active_subscription !== undefined) {

            subscriptions = [{
                                active: true,
                                expiration_date: new Date(active_subscription.expiryDate ?? active_subscription.purchaseDate ?? 0),
                                product_id: active_subscription.id
                            }];

        } else {

            const sorted_subscriptions = verified_purchases_payload.filter(purchase => {
                    const product = store.get(purchase.id, purchase.platform);
                    return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION;
                })
                .sort((a, b) => (a.expiryDate ?? a.purchaseDate ?? 0) - (b.expiryDate ?? b.purchaseDate ?? 0));

            let expired_subscription = sorted_subscriptions.slice(-1)[0];
            if (expired_subscription) {

                subscriptions = [{
                                    active: false,
                                    expiration_date: new Date(expired_subscription.expiryDate ?? expired_subscription.purchaseDate ?? 0),
                                    product_id: expired_subscription.id
                                }];
            }
        } 
    }

    // console.log("------> Subscriptions purchased/expired: ", JSON.stringify(active_subscription), JSON.stringify(expired_subscription));

    return {
        products,
        subscriptions
    };
}

async function activate_subscription(billing_system: BillingSystem, token: string)
    : Promise<boolean> {

    if (token === undefined) {

        log(CdvPurchase.store.defaultPlatform(), "Error during activation: 'token' is undefined.");
        throw new Error("Error during activation: 'token' is undefined.");
        // return false;
    }

    let form_data = new FormData();
    form_data.append('token', token);
    form_data.append('billing_system', billing_system);

    let result = await fetch("/billing/subscriptions/activate.json", 
                            {
                                method: 'POST',
                                body: form_data
                            });

    log(CdvPurchase.store.defaultPlatform(), `Result from POST: http status: ${result.status} -> ${JSON.stringify(result)}`);
    
    return result.status == 200; 
}

function initialize_subscriptions(platform: CdvPurchase.Platform, subscription_status: AccountSubscriptionStatus) {

    //log(platform, "Initializing products...");

    let store = CdvPurchase.store;

    const register_products: CdvPurchase.IRegisterProduct[] = [
            ...GooglePlay.product_ids.map(product_id => ({
                                                            id: product_id,
                                                            platform: CdvPurchase.Platform.GOOGLE_PLAY,
                                                            type: CdvPurchase.ProductType.PAID_SUBSCRIPTION
                                                         })),
            ...AppleAppStore.product_ids.map(product_id => ({
                                                            id: product_id,
                                                            platform: CdvPurchase.Platform.APPLE_APPSTORE,
                                                            type: CdvPurchase.ProductType.PAID_SUBSCRIPTION
                                                         }))
    ];

    store.register(register_products);

    // setup callback serverside handler for validating receipts
    let url_slug_platform: BillingSystem;
    if (platform === CdvPurchase.Platform.GOOGLE_PLAY) {
        url_slug_platform = 'google_play';
    } else {
        url_slug_platform = 'apple_appstore';
    }

    store.validator = new URL(`/billing/subscriptions/${url_slug_platform}/validator`, env_client.www_endpoint()).toString();

    if (env_client.is_dev()) {
        store.verbosity = CdvPurchase.LogLevel.DEBUG;
    }

    // setup event handlers
    store.when()
        .productUpdated(() => {
            //log(platform, "Lifecycle - Product updated");
            let subscription_state = parse_registered_products_from_store(store);
            subscription_store.product_updated(subscription_state);
        })
        .receiptsReady(() => {

            /*
             * Called once after startup with all receipts - 
             * can be used to check state of subscriptions at startup and compare for inconsistencies.
             * 
             * When a subscription is 'paused', then the corresponding receipt won't be provided to this
             * event handler. Once the subscription is 'unpaused', then the receipt will be provided.
             */

            //log(platform, `Lifecycle - Receipts ready: ${JSON.stringify(store.localTransactions)}`);
            let subscription_state = parse_registered_products_from_store(store);
            subscription_store.receipts_ready(subscription_state);

        })
        .receiptsVerified(() => {
            // called once after startup after all receipts have been verified by the validator - can be used to check state of subscriptions at startup and compare for inconsistencies
            // TODO: Check this to see if it actually calls the validator endpoint - YES: validator call happens at startup and triggers this callback
            // TODO: Use this to show message if transaction is still 'pending'
            //log(platform, "Lifecycle - Receipts verified. ");
        })
        .receiptUpdated(receipt => {
            //log(platform, `Lifecycle - Receipt updated: ${JSON.stringify(receipt.transactions.map(item => item.transactionId))}`);
            // let subscription_state = parse_registered_products_from_store(store);
            // subscription_store.receipts_updated(subscription_state);
        })
        .approved(transaction => {

            /*
             * This is called when a user approves the native purchase popup AND 
             * at startup when it detects products that were purchased.
             * 
             * Essentially, this kicks off the validator to check that the transaction 
             * is valid or is still valid.
             */

            log(platform, `Lifecycle - Approved transaction: ${JSON.stringify(transaction)}`);

            let subscription_state = parse_registered_products_from_store(store);
            subscription_store.approved_transaction(subscription_state);                

            transaction.verify().catch((error) => {
                if (error) {
                    log(platform, `Lifecycle - Approved Transaction Error: ${error.message}`);
                    // subscription_store.set_error(error);
                }
            });
        })
        .verified(receipt => {

            /*
             * Lifecycle method that is called every time a transaction is verified.
             * This can occur when a user is making a new purchase, or at startup
             * when the system detects a previously purchased product and needs to 
             * verify it is still active. 
             * 
             * The sole role of this method is to call 'finish' on the receipt 
             * to acknowledge that the transaction has been verified and that the
             * purchase is good. If this transaction had already been acknowledged,
             * then no further events are fired. Otherwise, the 'finished' event
             * handler is invoked.
             */

            log(platform, `Lifecycle - Verified receipt: ${JSON.stringify(receipt)}`);

            let subscription_state = parse_registered_products_from_store(store);

            let pending_transactions = receipt.sourceReceipt.transactions.filter(transaction => transaction.isPending);
            if (pending_transactions.length > 0) {

                log(platform, `Lifecycle - Verified - Pending transactions: ${JSON.stringify(pending_transactions)}`);
                subscription_store.pending_purchase(pending_transactions, subscription_state);

                // TODO: Have a subscription_store event listener kick off a timer to recheck every 10 minutes?

            } else {

                subscription_store.verified(subscription_state);

                /*
                * Activate server-side.
                * 
                * NOTE: This only get called after a new transaction is acknowledged.
                *       Previous, still-active transactions won't trigger this, despite the 'verified' call to 'finish'.
                * NOTE: Google-Play -> 'transaction.purchaseId' is the same thing as 'purchaseToken'.
                */
                let last_transaction = receipt.sourceReceipt.lastTransaction();
                let lookup_token: string;
                if (platform === CdvPurchase.Platform.APPLE_APPSTORE) {
    
                    // let apple_transaction = last_transaction as CdvPurchase.AppleAppStore.SKTransaction;
                    // lookup_token = apple_transaction.originalTransactionId ?? apple_transaction.transactionId;
                    lookup_token = (receipt.sourceReceipt as CdvPurchase.AppleAppStore.SKApplicationReceipt).nativeData.appStoreReceipt;

                } else {
    
                    lookup_token = last_transaction.purchaseId!;
                }    

                // skip server activation if user is already paying
                if (subscription_status === 'paying') {

                    log(platform, "Lifecycle - Verified: User is already paying - skipping activation.");

                    receipt.finish();

                    return;
                }

                log(platform, "Lifecycle - Verified: Activating subscription serverside.");
                activate_subscription(url_slug_platform, lookup_token!).then((success) => {

                    log(platform, `Lifecycle - Verified - Server Activation Callback: ${success}`);

                    if (success) {
                        
                        log(platform, `Lifecycle - Verified - Server Activation Success - calling finish`);
                        receipt.finish();

                        let subscription_state = parse_registered_products_from_store(store);
                        subscription_store.complete_purchase(subscription_state);

                    } else {

                        log(platform, "Lifecycle - Verified - Server Activation Failure.");

                        subscription_store.set_error({code: 500, 
                                                    message: "Failed to activate subscription serverside. Please wait 10 minutes.", 
                                                    isError: true, 
                                                    platform: platform, 
                                                    productId: ''});
                        setTimeout(() => {
                            subscription_store.reset_state();
                        }, DISPLAY_ERROR_DURATION_IN_MILLIS);
                    }
                });
            }
        })
        .pending(transaction => {
            
            // TODO: Do anything here?
            log(platform, `Lifecycle - Pending: ${JSON.stringify(transaction)}`);
            let subscription_state = parse_registered_products_from_store(store);
            subscription_store.pending_purchase([transaction], subscription_state);

        })
        .unverified(unverified => {

            log(platform, `Lifecycle - Unverified: ${JSON.stringify(unverified)}`);
            let subscription_state = parse_registered_products_from_store(store);
            subscription_store.unverified(subscription_state);

        })
        .finished(transaction => {
            log(platform, `Lifecycle - Finished transaction: ${JSON.stringify(transaction)}`);
        });   
        
    store.error(error => {                
        log(platform, `Lifecycle - General Error: ${error.code, error.message}`);
        subscription_store.set_error(error);
        setTimeout(() => {
            subscription_store.reset_state();
        }, DISPLAY_ERROR_DURATION_IN_MILLIS);        
    });

    // call store to initialize products
    store.initialize([
        CdvPurchase.Platform.GOOGLE_PLAY,
        {
            platform: CdvPurchase.Platform.APPLE_APPSTORE,
            options: {
                needAppReceipt: true,
                autoFinish: true 
            }
        }
        ])
        .then((value: CdvPurchase.IError[]): void => {
            log(platform, `Store initialized - [${JSON.stringify(value)}]`);
            subscription_store.complete_initialization();
        }); 
        
    return store;
}

class ClientSubscriptionService {

    private store?: CdvPurchase.Store;
    private platform?: CdvPurchase.Platform;

    initialize(platform: CdvPurchase.Platform, subscription_status: AccountSubscriptionStatus) {

        this.platform = platform;

        this.store = initialize_subscriptions(platform, subscription_status);
    }

    subscribe(product_id: string, offer_id: string) {

        if (this.store === undefined 
            || this.platform === undefined 
            || !subscription_store.state().initialized) {

            throw new Error("Subscription service not initialized");
        }

        log(this.platform!, `Lifecycle - Subscribing to product: ${product_id} - ${offer_id}`);
        subscription_store.purchase(product_id);

        this.store.get(product_id, this.platform)?.getOffer(offer_id)?.order()
            .then((error) => {

                log(this.platform!, `Lifecycle - Performed order for product: ${product_id} - ${offer_id}`);

                if (error) {
                    
                    log(this.platform!, `Lifecycle - Error during order: ${error.code} ${error.message}`);

                    subscription_store.set_error(error);
                    setTimeout(() => {
                        subscription_store.reset_state();
                    }, DISPLAY_ERROR_DURATION_IN_MILLIS);        
                }
            });
    }

    // TODO: Need for this w/ subscriptions?
    // restore_purchases() {

    //     if (this.store === undefined || this.platform === undefined) {
    //         throw new Error("Subscription service not initialized");
    //     }

    //     log(this.platform!, `Restoring purchases.`);
    //     subscription_store.refresh();
    //     this.store.restorePurchases()
    //         .then(() => {
    //             log(this.platform!, `Restored purchases.`);
    //             subscription_store.complete_refresh();
    //         });
    // }
}

export const client_subscription_service = new ClientSubscriptionService();