<script lang="ts">
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';
	import { Capacitor } from '@capacitor/core';
	import type { AuthErrorType } from '$lib/auth/auth_constants';

    interface Props {
        visible?: boolean;
        auth_error_type: AuthErrorType;
    }

    let { visible = $bindable(false), auth_error_type }: Props = $props();

    function toggle_modal() {
        visible = !visible;
    }

    let is_ios = $state(false);
    // detect if iOS
    onMount(() => {
        
        is_ios = Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
    });

</script>

{#if visible}

    <div class="z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
        Background backdrop, show/hide based on modal state.

        Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
        Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div transition:fade class="fixed inset-0 bg-primary transition-opacity"></div>

        <div class="fixed inset-0 z-50 overflow-y-auto">
            <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                    From: "opacity-100 translate-y-0 sm:scale-100"
                    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                -->
                <div transition:fade class="relative transform overflow-hidden shadow-2xl rounded-3xl bg-backdrop-light px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div class="absolute top-0 right-0 block pt-4 pr-4">
                        <button onclick={toggle_modal} onkeypress={toggle_modal} type="button" class="rounded-md bg-backdrop-light text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span class="sr-only">Close</span>
                        <!-- Heroicon name: outline/x-mark -->
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                    <div class="flex flex-col items-center space-y-4">
                        <div class="flex flex-col items-center">
                            <h3 class="text-xl font-medium text-gray-900" id="modal-title">
                                Oh no! 
                            </h3>
                            <h4 class="text-base">
                                We could not authenticate you.
                            </h4>
                        </div>
                        <img class="w-24 block mx-auto" src="/logo/swimerize-icon-light-bg.svg" alt="Swimerize Logo" />
                        <div class="text-sm font-normal text-primary-text dark:text-gray-400">
                            <!-- {#if reviewer_credentials_expanded}
                                <span class="font-bold">Log in</span> with your Swimerize credentials.
                            {:else}
                                <span class="font-bold">Log in</span> with any of these identity providers.
                            {/if} -->
                        </div>
                    </div>
                    <div class="my-4 space-y-8 text-sm">
                        {#if auth_error_type === 'invalid_authentication_state'}
                            {#if is_ios}
                                <p>
                                    <span class="font-semibold mr-2">
                                        iOS Users:
                                    </span>
                                    If your default browser is not Safari, 
                                    then you will need to temporarily set Safari as your default
                                    browser to log in (you can change it back after logging in).                                
                                </p>
                            {/if}
                            <p>
                                Learn more about this and other related login issues 
                                in our 
                                <a href="/faq?question_id={auth_error_type}" 
                                target="_blank" 
                                class="text-primary-text dark:text-gray-400 underline">
                                    
                                    FAQ
                                </a>.
                            </p>
                        {/if}

                        {#if auth_error_type === 'unverified_email'}
                            <p>
                                You are attempting to log in with 
                                an email address that has not been verified.
                                Please visit your authentication provider's website 
                                to verify your email address.
                            </p>
                            <p>
                                Learn more about this and other related login issues 
                                in our 
                                <a href="/faq?question_id={auth_error_type}" 
                                target="_blank" 
                                class="text-primary-text dark:text-gray-400 underline">
                                    
                                    FAQ
                                </a>.
                            </p>
                        {/if}
                         
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}