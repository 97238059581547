<script lang="ts">
	import env_client from "$lib/env/env_clientside";
	import { generate_profile_header_image_url } from "$lib/picture/picture_utils";
	import type { UserAccountDTO } from "$lib/server/entities/user_account/UserAccountEntity";
	import type { UserSessionSettings } from "$lib/session/session_initializer.svelte";
    import { twMerge } from 'tailwind-merge';

    interface Props {
        user_account?: UserAccountDTO;
        user_settings?: Pick<UserSessionSettings, 'display_name' | 'profile_picture_pathname' | 'profile_picture_url' | 'subscription_status'>;
        class?: string;
    }

    let {
        user_account = $bindable(),
        user_settings = $bindable(),
        class: class_override
    }: Props = $props();

    let class_data = twMerge("", class_override);

    let name: string | undefined = $derived(user_account?.name ?? user_settings?.display_name ?? '');
    let is_subscribed = $derived.by(() => {

        let to_return = false;
        if (user_account) {
            to_return = user_account.subscription_status === 'paying' || user_account.subscription_status === 'trial';

        } else if (user_settings) {
            to_return = user_settings.subscription_status === 'paying' || user_settings.subscription_status === 'trial'; 
        }

        return to_return;
    });

    let picture_url: string | undefined = $derived.by(() => {

        let profile_picture_path;
        let profile_picture_url;

        // initialize from user_account (if present)
        if (user_account) {

            profile_picture_path = user_account.profile_picture?.path_name;
            profile_picture_url = user_account.profile_picture_url;

        } else if (user_settings) {

            profile_picture_path = user_settings.profile_picture_pathname;
            profile_picture_url = user_settings.profile_picture_url

        } else {
            throw new Error("UserAccountAvatar requires either a user_account or user_settings to be passed in.");
        }

        let to_return;
        if (profile_picture_path) {
            to_return = generate_profile_header_image_url(profile_picture_path, env_client.s3_endpoints());
        } else if (profile_picture_url) {
            to_return = profile_picture_url;
        } else {
            to_return = undefined;
        }

        return to_return;
    });

</script>

<div class="avatar_parent {class_data}">

    <div class="avatar" class:subscribed={is_subscribed} class:freemium={!is_subscribed}>       
        {#if picture_url}
    
            <img src="{picture_url}" alt="Profile for {name ?? 'user'}" />
    
        {:else}
    
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" > <!-- class="{class_data}" -->
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
    
        {/if}
    </div>    
</div>