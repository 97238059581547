<script lang="ts">
	import { page } from '$app/state';
	import { fade } from 'svelte/transition';
	import { show_login } from '$lib/stores/login_store';
	import { onMount } from 'svelte';
	import FailedLoginTakeover from '$lib/components/homepage/FailedLoginTakeover.svelte';
	import type { AuthErrorType } from '$lib/auth/auth_constants';
	import { Capacitor } from '@capacitor/core';
	import { scroll_component_into_view } from '$lib/ui_utils';
	import { APPLE_APP_STORE_URL, GOOGLE_PLAY_URL } from '$lib/ui_constants';
	import SubscriptionFeaturesTable from '$lib/components/subscription/SubscriptionFeaturesTable.svelte';
	import Button from '$lib/components/Button.svelte';
	import env_client from '$lib/env/env_clientside';

	//console.log("Homepage: ", page.data);
	const IS_PRICING_LIVE = true;

	let google_play_url = new URL(GOOGLE_PLAY_URL);
    google_play_url.searchParams.append('pcampaignid', 'homepage');

	let failed_login_takeover_visible = $state(false);
	let show_login_signup_section = Capacitor.isNativePlatform();

	let app_preview_carousel: {title: string, text: string, image: URL, backdrop_class: string, id: string, selected: boolean}[] = $state([
		{
			title: "Find your friends and make new ones",
			text: "Never swim alone again. Coordinate with your friends and make new ones at your favorite swimming spots.",
			image: new URL("/images/phone-friends.webp", env_client.www_endpoint()),
			id: "app-preview-find-friends",
			selected: true,
			backdrop_class: 'bg-backdrop-dark'
		},

		{
			title: "Visualize your progress",
			text: "Disect your swims using various metrics. Visualize your swims using realtime animations to see the results of your efforts!",
			image: new URL("/images/phone-pool-swim.webp", env_client.www_endpoint()),
			id: "app-preview-analyze-metrics",
			selected: false,
			backdrop_class: 'bg-backdrop-dark'
		},

		{
			title: "Connect with your swim club",
			text: "Join or create a swim club in your area. Coordinate events, share tips, and compete with each other.",
			image: new URL("/images/phone-club.webp", env_client.www_endpoint()),
			id: "app-preview-swim-club",
			selected: false,
			backdrop_class: 'bg-backdrop-dark'
		},
		
	]);

	function highlight_app_preview(event: any, idx: number) {

		// disable previous
		app_preview_carousel.forEach((item: any) => {if (item.selected === true) { item.selected = false; }});

		app_preview_carousel[idx].selected = true;
		app_preview_carousel = [...app_preview_carousel]; // trigger reactivity

		let app_preview = app_preview_carousel[idx];
		scroll_component_into_view(app_preview.id);
	}

	let is_ios: boolean;
	let is_native_app: boolean;
	let auth_error: AuthErrorType | undefined = $state();
    onMount(() => {

		let is_show_login = page.url.searchParams.get('show_login') === 'true';
		if (is_show_login) {
			// console.log("---------> NOT LOGGED IN");
			show_login.set(true);
		}

		auth_error = page.url.searchParams.get('auth_error') as AuthErrorType;
		failed_login_takeover_visible = auth_error !== undefined && auth_error !== null;

		is_native_app = Capacitor.isNativePlatform();
		is_ios = is_native_app && Capacitor.getPlatform() === 'ios';

		let target_hash = page.url.hash?.substring(1);
		if (target_hash) {
			scroll_component_into_view(target_hash);
		}
    });

	function show_login_modal() {
		show_login.set(true);
	}

</script>

<svelte:head>
	<title>Swimerize</title>
	<meta name="robots" content="all" />
	<meta name="description" content="Swimerize is a growing community of swimmers where you can meet like-minded swimmers, analyze your swims, and discover new swim destinations.">
</svelte:head>

{#if auth_error !== undefined}
	<FailedLoginTakeover bind:visible={failed_login_takeover_visible} auth_error_type={auth_error} />
{/if}

{#if failed_login_takeover_visible === false}
	<main class="lg:-mx-20 scroll-smooth">

		<!-- Signup/Login or App Store links -->
		<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-backdrop-dark">
			
			<div class="sm:mx-auto sm:w-full sm:max-w-sm">
				<img class="mx-auto h-14 w-auto" src="/logo/swimerize-icon-light-bg.svg" alt="Swimerize">
				<h2 class="mt-4 mb-8 text-center text-2xl font-bold leading-9 tracking-tight text-primary-foreground-enabled">
					<!-- Dive right in -->
					Make a Splash
				</h2>
			</div>

			{#if show_login_signup_section}
				<div class="space-y-2 flex flex-col place-items-center">
					<button onclick={show_login_modal}
							onkeypress={show_login_modal}
							class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
						Join
					</button>
					<span class="text-primary-foreground-disabled text-xs font-light">
						or
					</span>
					<button onclick={show_login_modal}
							onkeypress={show_login_modal}
							class="text-primary-foreground-disabled font-normal text-sm italic underline">
						Log in
					</button>
				</div>
			{:else}

				<!-- Google Play and Apple Store links. -->
				<div class="flex flex-col place-content-center space-y-3">
					<a aria-label="Download on the Google Play Store"
						href="{google_play_url.toString()}">

						<img class="w-full h-16"
								src="/images/Google_Play-Badge-Logo.svg"
								alt="Download at Google Play"/>
					</a>
					<a aria-label="Download on the Apple App Store" 
						href="{APPLE_APP_STORE_URL}">

						<img class="w-full h-12"
								src="/images/Apple_Store-Badge-Logo.svg"
								alt="Download at Apple Store"/>
					</a>
				</div>

			{/if}
		</div>		

		<!-- Learn More section -->
		<div id="learn-more" class="overflow-hidden pt-20 pb-5 sm:py-32 lg:pb-32 xl:pb-36">
			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div class="mb-8 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
					<div
						class="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6"
					>
						<h1 class="text-4xl font-medium tracking-tight text-gray-900">
							Join the growing community of swimmers.
						</h1>
						<p class="mt-6 text-lg text-gray-600">
							Chart your progress, analyze your improvements, meet fellow-minded swimmers,
							compare your swims to theirs, and discover new challenges!
						</p>
					</div>

					<div class="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
						<!-- Rotating background -->
						<div
							class="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0"
						>
							<svg
								viewBox="0 0 1026 1026"
								fill="none"
								aria-hidden="true"
								class="absolute inset-0 h-full w-full animate-spin-slow"
								><path
									d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
									stroke="#E5E5E5"
									stroke-opacity="0.7"
								/><path
									d="M513 1025C230.23 1025 1 795.77 1 513"
									stroke="url(#:R65m:-gradient-1)"
									stroke-linecap="round"
								/><defs
									><linearGradient
										id=":R65m:-gradient-1"
										x1="1"
										y1="513"
										x2="1"
										y2="1025"
										gradientUnits="userSpaceOnUse"
										><stop stop-color="#C026D3" /><stop
											offset="1"
											stop-color="#C026D3"
											stop-opacity="0"
										/></linearGradient
									></defs
								></svg
							><svg
								viewBox="0 0 1026 1026"
								fill="none"
								aria-hidden="true"
								class="absolute inset-0 h-full w-full animate-spin-reverse-slower"
								><path
									d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
									stroke="#E5E5E5"
									stroke-opacity="0.7"
								/><path
									d="M913 513c0 220.914-179.086 400-400 400"
									stroke="url(#:R65m:-gradient-2)"
									stroke-linecap="round"
								/><defs
									><linearGradient
										id=":R65m:-gradient-2"
										x1="913"
										y1="513"
										x2="913"
										y2="913"
										gradientUnits="userSpaceOnUse"
										><stop stop-color="#C026D3" /><stop
											offset="1"
											stop-color="#C026D3"
											stop-opacity="0"
										/></linearGradient
									></defs
								></svg
							>
						</div>
						<div
							class="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-top-10 lg:-bottom-20 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32"
						>
							<div class="relative aspect-[366/729] mx-auto max-w-[366px]">
								<div class="absolute inset-y-[calc(1/729*100%)] right-[calc(5/729*100%)] left-[calc(7/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl">
								</div>
								<div class="absolute top-[calc(23/729*100%)] left-[calc(23/366*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-backdrop-dark pt-[calc(23/318*100%)]">
									<img src="/images/phone-content-pb.webp" alt="" />
								</div>
								<svg
									viewBox="0 0 366 729"
									aria-hidden="true"
									class="pointer-events-none absolute inset-0 h-full w-full fill-gray-100"
									><path
										fill="#F2F2F2"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"
									/><rect x="154" y="29" width="56" height="5" rx="2.5" fill="#E5E5E5" /></svg
								><img
									alt=""
									src="/images/phone-frame.svg"
									width="366"
									height="729"
									decoding="async"
									data-nimg="future"
									class="pointer-events-none absolute inset-0 h-full w-full"
									style="color:transparent"
								/>
							</div>
						</div>
					</div>
					<!-- <div class="md:hidden mt-8 grid grid-cols-2 place-items-center">
						<a aria-label="Download on the Apple App Store" href="https://apps.apple.com/us/story/id">
							<img
								class="w-full h-9"
								src="/images/Apple_Store-Badge-Logo.svg"
								alt="Download at Apple Store"
							/>
						</a>
						<a
							aria-label="Download on the Google Play Store"
							href="https://play.google.com/store/apps/details"
						>
							<img
								class="w-full h-24"
								src="/images/Google_Play-Badge-Logo.svg"
								alt="Download at Google Play"
							/>
						</a>
					</div> -->
				</div>
			</div>
		</div>

		<!--
		Features Section w/ app image.
	-->
		<section
			id="features"
			aria-label="Open water swimming app features"
			class="bg-backdrop-dark py-20 sm:py-32"
		>
			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
					<h2 class="text-3xl font-medium tracking-tight text-white">
						For swimmers, 
						<br/>
						by swimmers.
					</h2>
					<p class="mt-2 text-lg text-gray-400">
						Swimerize was built specifically for open water and pool swimmers alike. 
						Join the community, analyze your swims, chart your
						progress, and more!
					</p>
				</div>
			</div>

			<!-- ----------- -->
			<!-- MOBILE VIEW -->
			<!-- ----------- -->
			<div class="mt-16 md:hidden">
				<div class="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden">
					
					{#each app_preview_carousel as app_preview}
						
						<div id="{app_preview.id}" class="w-full flex-none snap-center px-4 sm:px-6">
							<div class="relative transform overflow-hidden rounded-2xl bg-backdrop-dark px-5 py-6">
								<div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ">
									<svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true"
										><defs
											><linearGradient
												id=":R2m9m:"
												x1="79"
												y1="16"
												x2="105"
												y2="237"
												gradientUnits="userSpaceOnUse"
												><stop stop-color="#C026D3" /><stop
													offset="1"
													stop-color="#C026D3"
													stop-opacity="0"
												/></linearGradient
											></defs
										><path
											opacity=".2"
											d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
											stroke="#C026D3"
										/><path
											d="M1 279C1 125.465 125.465 1 279 1"
											stroke="url(#:R2m9m:)"
											stroke-linecap="round"
										/></svg
									>
								</div>
								<div class="relative aspect-[366/729] mx-auto w-full max-w-[366px]">
									<div class="absolute inset-y-[calc(1/729*100%)] right-[calc(5/729*100%)] left-[calc(7/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl">
									</div>
									<div
										class="{app_preview.backdrop_class} absolute top-[calc(23/729*100%)] left-[calc(23/366*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden pt-[calc(23/318*100%)]"
									>
										<div class="flex flex-col w-full">
											<img src="{app_preview.image.toString()}" alt="Preview of app" />
										</div>
									</div>
									<svg
										viewBox="0 0 366 729"
										aria-hidden="true"
										class="pointer-events-none absolute inset-0 h-full w-full fill-gray-100"
										><path
											fill="#F2F2F2"
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"
										/><rect x="154" y="29" width="56" height="5" rx="2.5" fill="#E5E5E5" /></svg
									><img
										alt=""
										src="/images/phone-frame.svg"
										width="366"
										height="729"
										decoding="async"
										data-nimg="future"
										class="pointer-events-none absolute inset-0 h-full w-full"
										loading="lazy"
										style="color:transparent"
									/>
								</div>

								<!-- First App Text -->
								<div class="absolute inset-x-0 bottom-0 bg-backdrop-dark/95 p-6 backdrop-blur sm:p-10">
									<!-- <svg viewBox="0 0 32 32" aria-hidden="true" class="h-8 w-8"
										><circle cx="16" cy="16" r="16" fill="#A3A3A3" fill-opacity="0.2" /><path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
											fill="#737373"
										/><path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
											fill="#A3A3A3"
										/></svg
									> -->
									<h3 class="mt-6 text-sm font-semibold text-white sm:text-lg">
										{app_preview.title}
									</h3>
									<p class="mt-2 text-sm text-gray-400">
										{app_preview.text}
									</p>
								</div>
							</div>
						</div>
					{/each}

				</div>

				<!-- Chiclet buttons below swipable cards -->
				<div class="mt-6 flex justify-center gap-3">
					{#each app_preview_carousel as app_preview, idx}
						<button type="button"
								class:bg-gray-300={app_preview.selected}
								class:bg-gray-500={!app_preview.selected}
								class="relative h-0.5 w-4 rounded-full bg-gray-300"
								aria-label="Go to slide {idx + 1}"
								onclick={(event) => {highlight_app_preview(event, idx)}}
								onkeypress={(event) => {highlight_app_preview(event, idx)}}>
							<span class="absolute -inset-x-1.5 -inset-y-3"></span>
						</button>					
					{/each}
				</div>
			</div>

			<!-- ------------------- -->
			<!-- DESKTOP/TABLET VIEW -->
			<!-- ------------------- -->
			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 hidden md:mt-20 md:block">
				<div class="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24">
					<div class="relative z-10 order-last col-span-6 space-y-6"
						role="tablist"
						aria-orientation="vertical">

						<!-- Desktop/Tablet Clickable Info Panels -->
						{#each app_preview_carousel as app_preview, idx}
							
							<div class="relative rounded-2xl transition-colors hover:bg-backdrop-dark/30">
								<div class="absolute inset-0 bg-backdrop-dark"
									 style="border-radius: 16px; opacity: 1;">
								</div>
								<div class="relative z-10 p-8">
									<!-- <svg viewBox="0 0 32 32" aria-hidden="true" class="h-8 w-8"
										><circle cx="16" cy="16" r="16" fill="#A3A3A3" fill-opacity="0.2" /><path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
											fill="#737373"
										/><path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
											fill="#A3A3A3"
										/></svg
									> -->
									<h3 class="mt-6 text-lg font-semibold text-white">
										<button
											class="text-left [&:not(:focus-visible)]:focus:outline-none"
											id="headlessui-tabs-tab-:R2ir9m:"
											role="tab"
											type="button"
											aria-selected="true"
											tabindex="0"
											data-headlessui-state="selected"
											aria-controls="headlessui-tabs-panel-:R2lb9m:"
											onclick={(event) => {highlight_app_preview(event, idx)}}
											onkeypress={(event) => {highlight_app_preview(event, idx)}} >

											<span class="absolute inset-0 rounded-2xl">
											</span>
											{app_preview.title}
										</button>
									</h3>
									<p class="mt-2 text-sm text-gray-400">
										{app_preview.text}
									</p>
								</div>
							</div>
						{/each}
						<!-- <div class="relative rounded-2xl transition-colors hover:bg-backdrop-dark/30">
							<div class="relative z-10 p-8">
								<svg viewBox="0 0 32 32" aria-hidden="true" class="h-8 w-8"
									><circle cx="16" cy="16" r="16" fill="#A3A3A3" fill-opacity="0.2" /><path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z"
										fill="#A3A3A3"
									/><path
										d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z"
										fill="#737373"
									/></svg
								>
								<h3 class="mt-6 text-lg font-semibold text-white">
									<button
										class="text-left [&:not(:focus-visible)]:focus:outline-none"
										id="headlessui-tabs-tab-:R2kr9m:"
										role="tab"
										type="button"
										aria-selected="false"
										tabindex="-1"
										data-headlessui-state=""
										><span class="absolute inset-0 rounded-2xl" />{callout_text[1].headline}</button
									>
								</h3>
								<p class="mt-2 text-sm text-gray-400">
									{callout_text[1].body}
								</p>
							</div>
						</div>
						<div class="relative rounded-2xl transition-colors hover:bg-backdrop-dark/30">
							<div class="relative z-10 p-8">
								<svg viewBox="0 0 32 32" fill="none" aria-hidden="true" class="h-8 w-8"
									><defs
										><linearGradient
											id=":R1mr9m:-gradient"
											x1="14"
											y1="14.5"
											x2="7"
											y2="17"
											gradientUnits="userSpaceOnUse"
											><stop stop-color="#737373" /><stop
												offset="1"
												stop-color="#E5E5E5"
												stop-opacity="0"
											/></linearGradient
										></defs
									><circle cx="16" cy="16" r="16" fill="#A3A3A3" fill-opacity="0.2" /><path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z"
										fill="#A3A3A3"
									/><path
										d="M7 22c0-4.694 3.5-8 8-8"
										stroke="url(#:R1mr9m:-gradient)"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/><path
										d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z"
										fill="#A3A3A3"
									/></svg
								>
								<h3 class="mt-6 text-lg font-semibold text-white">
									<button
										class="text-left [&:not(:focus-visible)]:focus:outline-none"
										id="headlessui-tabs-tab-:R2mr9m:"
										role="tab"
										type="button"
										aria-selected="false"
										tabindex="-1"
										data-headlessui-state=""
										><span class="absolute inset-0 rounded-2xl" />{callout_text[2].headline}</button
									>
								</h3>
								<p class="mt-2 text-sm text-gray-400">
									{callout_text[2].body}
								</p>
							</div>
						</div> -->
					</div>

					<!-- Desktop/Tablet App Screens -->
					<div class="relative col-span-6">
						<div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
							<svg
								viewBox="0 0 558 558"
								width="558"
								height="558"
								fill="none"
								aria-hidden="true"
								class="animate-spin-slower"
								><defs
									><linearGradient
										id=":R3b9m:"
										x1="79"
										y1="16"
										x2="105"
										y2="237"
										gradientUnits="userSpaceOnUse"
										><stop stop-color="#C026D3" /><stop
											offset="1"
											stop-color="#C026D3"
											stop-opacity="0"
										/></linearGradient
									></defs
								><path
									opacity=".2"
									d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
									stroke="#C026D3"
								/><path
									d="M1 279C1 125.465 125.465 1 279 1"
									stroke="url(#:R3b9m:)"
									stroke-linecap="round"
								/></svg
							>
						</div>
						<div class="relative aspect-[366/729] z-10 mx-auto w-full max-w-[366px]">
							<div class="absolute inset-y-[calc(1/729*100%)] right-[calc(5/729*100%)] left-[calc(7/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl">
							</div>
							<div
								class="absolute top-[calc(23/729*100%)] left-[calc(23/366*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-backdrop-dark pt-[calc(23/318*100%)]"
							>
								<div
									class="col-start-1 row-start-1 flex focus:outline-offset-[32px] [&:not(:focus-visible)]:focus:outline-none"
									id="headlessui-tabs-panel-:R2lb9m:"
									role="tabpanel"
									data-headlessui-state="selected"
									aria-labelledby="headlessui-tabs-tab-:R2ir9m:"
								>
									<div class="flex flex-col w-full">
										{#each app_preview_carousel as app_preview}

											<!-- Phone App Screenshot -->
											{#if app_preview.selected}
												<img transition:fade={{duration: 250}} 
													src="{app_preview.image.toString()}" 
													alt="Preview of app" 
													class="rounded-3xl w-72 mt-4 ml-4" />										
											{/if}

										{/each}
									</div>
								</div>
								<img
									alt=""
									src="/images/phone-frame.svg"
									width="366"
									height="729"
									decoding="async"
									data-nimg="future"
									class="pointer-events-none absolute inset-0 h-full w-full"
									loading="lazy"
									style="color:transparent"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Pricing Section -->
		{#if IS_PRICING_LIVE}
			<section
				id="pricing"
				aria-labelledby="pricing-title"
				class="border-t border-gray-200 bg-gray-100 py-20 sm:py-32 flex place-content-center">

				<div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
					<div class="mx-auto max-w-2xl text-center">
						<h2 id="pricing-title" class="text-3xl font-medium tracking-tight text-gray-900">
							Connect, have fun, learn, and improve.
						</h2>
						<p class="mt-2 text-lg text-gray-600">
							Join for free.
							<br /> 
							Become a paying member to take your swims to the next
							level!
						</p>
					</div>
					<div class="w-full flex place-content-center">
						<Button onclick={async () => show_login_modal()}
							class="mt-8 w-full flex place-content-center mx-16">

							Join for free
						</Button>
					</div>
					<!-- <div class="lg:mx-40 mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-2">

						<SubscriptionPackageFreeComponent action_callback={show_login_modal}/>
						<SubscriptionPackagePayingComponent action_callback={show_login_modal} />

					</div> -->
					<div class="mt-16 rounded-2xl p-8 bg-backdrop-dark">
						<SubscriptionFeaturesTable />
					</div>

				</div>
			</section>
		{/if}
	</main>
{/if}