<script lang="ts">

    interface Props {
      message?: string;
      spinner_color?: 'primary' | 'inverse';
      spinner_class_override?: string;
      class?: string;
    }

    let {
      message,
      spinner_color = 'primary',
      spinner_class_override = "",
      class: class_override = "",
    }: Props = $props();

</script>

<div class="inline-flex items-center rounded-full {class_override}">
    <div class:border-t-primary={spinner_color === 'primary'}
         class:border-t-white={spinner_color === 'inverse'} 
         class="loader border-t-2 rounded-full h-4 w-4 {spinner_class_override}"></div>
    {#if message}
        <span class="ml-3">{message}</span>
    {/if}
</div>

<style>
    .loader {
      /* margin: auto; */
      /* border: 20px solid #EAF0F6; */
      /* border-radius: 50%; */
      /* border-top: 20px solid #FF7A59; */
      /* width: 200px;
      height: 200px; */
      animation: spinner 2s linear infinite;
    }
    
    @keyframes spinner {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
</style>
  