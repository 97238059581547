<script lang="ts">
    import { Button, Dropdown, DropdownItem } from 'flowbite-svelte';
	import UserAccountAvatar from '../UserAccountAvatar.svelte';
	import type { AlertDTO } from '$lib/server/entities/alert/AlertEntity';
	import { onDestroy, onMount } from 'svelte';
	import { formatDistanceToNow } from 'date-fns';
	import GroupJoinInviteAlertItem from './GroupJoinInviteAlertItem.svelte';
	import GroupJoinInviteAcceptedAlertItem from './GroupJoinInviteAcceptedAlertItem.svelte';
	import GroupJoinRequestAlertItem from './GroupJoinRequestAlertItem.svelte';
	import GroupJoinRequestAcceptedAlertItem from './GroupJoinRequestAcceptedAlertItem.svelte';
	import FollowRequestAlertItem from './FollowRequestAlertItem.svelte';
	import env_client from '$lib/env/env_clientside';
	import ReactionAlertItem from './ReactionAlertItem.svelte';
	import { fetch_json_w_retry } from '$lib/utils';
	import { get_session } from '$lib/session/session_initializer.svelte';


    let {user_settings} = get_session();
    let user_logged_in = user_settings?.enabled ?? false;

    let items: AlertDTO[] = $state([]);
    let unread_alerts = $state(false);
    let open = $state(false);

    // $inspect(items, unread_alerts).with(console.trace);

    const CHECK_INTERVAL_IN_MILLIS = env_client.is_dev() ? 1000 * 60 : 1000 * 60 * 15; // 1 minute in Dev, 15 mins in Prod

    function refresh_alerts() {

        fetch_json_w_retry(new URL(`/alerts.json`, env_client.www_endpoint()), fetch)
            .then(data => {

                items = [...data?.alerts ?? []];
                let unread_items = items.filter(item => item.read_timestamp === undefined);
                unread_alerts = unread_items.length > 0;

                items.forEach(item => item.creation_timestamp = new Date(item.creation_timestamp));

                // console.log("Alerts: ", items, unread_alerts);
            });
    }

    let timer_id: any = undefined;
    onMount(() => {

        if (user_logged_in) {

            refresh_alerts();

            // repeating timer to check for new alerts
            timer_id = setInterval(() => {
                                            refresh_alerts();
                                         }, 
                                   CHECK_INTERVAL_IN_MILLIS);
}
    });

    onDestroy(() => {
        if (timer_id !== undefined) {
            clearInterval(timer_id);
        }
    });

    async function perform_server_command(command: 'mark_read' | 'clear', timestamp: Date) {

        let form_data = new FormData();
        form_data.set('command', command);
        form_data.set('timestamp_command_threshold', JSON.stringify(timestamp));

        await fetch("/alerts.json", 
                {
                    method: 'POST',
                    body: form_data
                }).then( (result) => {

                //console.log("Result from POST: ", result);

                if (result.status == 200) {
                    console.log("Successful push notification token registration.");
                }
            });
    }

    async function mark_read() {

        let unread_items = items.filter(item => item.read_timestamp === undefined);
        if (unread_items.length === 0) {
            return;
        }

        let timestamp = unread_items[0].creation_timestamp;

        await perform_server_command('mark_read', timestamp);

        unread_items.forEach(item => item.read_timestamp = timestamp);
    }

    async function clear() {

        open = false;

        let timestamp = items[0].creation_timestamp;
        await perform_server_command('clear', timestamp);

        items = [];
        unread_alerts = false;
    }

    function toggle(event: any) {

        open = event.detail;
        if (open) {
            unread_alerts = false;
            mark_read();
        }
    }

    function actioned() {
        open = false;
    }

</script>

{#if items.length > 0}  
    <div id="bell" class:active={unread_alerts} class="inline-flex items-center text-sm font-medium text-center text-gray-500 focus:outline-none mr-4">
        {#if unread_alerts}
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M5.85 3.5a.75.75 0 0 0-1.117-1 9.719 9.719 0 0 0-2.348 4.876.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5ZM19.267 2.5a.75.75 0 1 0-1.118 1 8.22 8.22 0 0 1 1.987 4.124.75.75 0 0 0 1.48-.248A9.72 9.72 0 0 0 19.266 2.5Z" />
                <path fill-rule="evenodd" d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0 24.583 24.583 0 0 0 4.83-1.244.75.75 0 0 0 .298-1.205 8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z" clip-rule="evenodd" />
            </svg>
            <div class="flex relative">
                <div class="inline-flex relative -top-2 left-0 end-3 w-1.5 h-1.5 bg-primary rounded-full"></div>
            </div>
        {:else}
            <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z" clip-rule="evenodd" />
            </svg>
        {/if}
    </div>

    <Dropdown bind:open 
              on:show={toggle} 
              containerClass="w-3/4 max-h-[80vh] max-w-md overflow-y-auto bg-backdrop-light rounded-2xl divide-y divide-gray-100 shadow shadow-2xl z-50"
              placement="top-start">
        
        <div slot="header" class="flex content-between pt-2 pb-2 px-4 font-bold text-primary-text text-sm w-full">
            <div class="flex-1">
                Notifications          
            </div>
            <div class="flex-0">
                <Button on:click={clear} class="text-center font-medium inline-flex items-center justify-center px-2 py-1 text-xs text-primary-foreground-enabled bg-primary rounded">
                    Clear All
                </Button>
            </div>
        </div>
        
        {#each items as item}
            <DropdownItem class="flex flex-col space-x-4 rtl:space-x-reverse" 
                          on:click={actioned}>

                    <div class="flex-1 flex place-content-beween gap-2 w-full">
                        <UserAccountAvatar user_account={item.created_by_user_account} 
                                           class="flex-0 size-10" />
                        {#if item.type === 'group_invite'}
                            <GroupJoinInviteAlertItem {item} />
                        {:else if item.type === 'group_invite_accepted'}
                            <GroupJoinInviteAcceptedAlertItem {item} />
                        {:else if item.type === 'group_request'}
                            <GroupJoinRequestAlertItem {item} />
                        {:else if item.type === 'group_request_accepted'}
                            <GroupJoinRequestAcceptedAlertItem {item} />
                        {:else if item.type === 'following'}
                            <FollowRequestAlertItem {item} />
                        {:else if item.type === 'reaction'}
                            <ReactionAlertItem {item} />
                        {/if}
                        <div class="flex place-items-center pl-2">
                            &gt;    
                        </div>    
                    </div>
                    <span class="text-[0.6rem] text-primary-text-diminished flex-1 place-self-end">
                        {formatDistanceToNow(item.creation_timestamp)} ago
                    </span>
            </DropdownItem>
        {/each}
    </Dropdown>
{/if}

<style>

#bell.active {
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-iteration-count: infinite;
}  

@keyframes shake {
  10%, 90% {
    transform: translate3d(-2px, 2px, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 2px, 0);
  }

  40%, 60% {
    transform: translate3d(2px, -2px, 0);
  }
}
</style>
