<script lang="ts">
    import { Modal, Label, Textarea } from 'flowbite-svelte';
	import Button from "./Button.svelte";
    import * as Sentry from "@sentry/browser";
	import { get_session } from '$lib/session/session_initializer.svelte';

    let user_session = get_session();

    interface Props {
        visible?: boolean;
        default_button_label?: string;
        initial_message?: string;
        children?: import('svelte').Snippet;
    }

    let { 
            visible = $bindable(false), 
            default_button_label = "Report Error", 
            initial_message,
            children 
        }: Props = $props();

    let form_element: HTMLFormElement | undefined = $state();
    let validation_error: string | undefined = $state(undefined);
    let submission_successful = $state(false);

    async function report_error() {

        if (form_element === undefined) {
            return;
        }

        let form_data = new FormData(form_element);

        let message = form_data.get('message')?.toString().trim();
        if (!message) {
            validation_error = "Please add a message before submitting.";
            return;
        }

        let context = {
                        user_account_uuid: user_session.user_account_uuid,
                      };
        message = message + "\n\n" + JSON.stringify(context);

        validation_error = undefined;

        const eventId = Sentry.captureMessage("User Feedback");

        const userFeedback = {
            event_id: eventId,
            name: user_session.user_account_uuid ?? "Anonymous",
            email: undefined,
            comments: message
        };
        Sentry.captureUserFeedback(userFeedback);

        visible = false;
        submission_successful = true;
    }

</script>

{#if user_session?.user_settings.contact_enabled}

    {#if submission_successful === false}
        <button onclick={() => {visible = true;}}>
            {#if children}{@render children()}{:else}
                <div class="bg-primary text-primary-foreground-enabled font-semibold py-2 px-4 rounded-lg"
                    role="button"
                    tabindex="0">
                    {default_button_label}
                </div>
            {/if}
        </button>
    {:else}

        <div class="text-primary text-lg mt-8 font-semibold">
            Thank you! We'll look into it.
        </div>

    {/if}

    {#if visible}
        
        <Modal bind:open={visible} size="xs" autoclose={false} class="w-full">
            <form bind:this={form_element} class="flex flex-col space-y-6 place-content-start" action="#">

                <!-- <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">We're listening</h3> -->
                <Label class="">
                    <div class="mb-2 flex place-content-start">We're listening:</div>
                    <Textarea name='message'
                            value={initial_message}
                            rows={5}
                            placeholder="Did you run into an issue? Is there something you'd like to see?"
                            required={true} 
                            class="{validation_error ? 'border-red-600' : 'border-primary'} border-2 focus:!ring-primary focus:!ring-0 focus:!border-primary"/>
                    {#if validation_error}
                        <div class="text-red-600">
                            {validation_error}
                        </div>
                    {/if}
                    <!-- <Input type="text" 
                        name="description" 
                        placeholder="Did you run into an issue? Is there something you'd like to see?" required /> -->
                </Label>
                <div class="flex place-content-end">
                    <Button enable_spinner={true} onclick={report_error}>
                        Send
                    </Button>
                </div>
            </form>
        </Modal>    
    {/if}
{:else}
    <a href="mailto:support@swimerize.com" title="Contact Us" class="flex-inline max-w-fit place-content-center">
        {#if children}{@render children()}{:else}
            <div class="mt-8 bg-primary text-primary-foreground-enabled font-semibold py-2 px-4 rounded-lg"
                 role="button"
                 tabindex="0">
                
                {default_button_label}
            </div>
        {/if}
    </a>        
{/if}